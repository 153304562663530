import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../asset/image/close.svg";
import _ from "lodash";
import ManageConfig, { Firewall, Hub_IP, Hub_IP_Configure, Organization_Configuration, Quota, System_Configuration, Traffic_Policy, WAN_Profile } from "./ManageConfig";
import SystemConfig from "./SystemConfig";
import OrganizationConfig from "./OrganizationConfig";
import "./EditActionsPopup.css";

const EditActionsPopup = (props) => {
    const { authReducer, open, setOpen, row, setRow, confirmDialog, setConfirmDialog, inventoryChangeGroup, setUpgradeLicensePayload, isAuthorizedToBeta } = props;

    const deviceActionsList = [
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Traffic_Policy,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Firewall,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && WAN_Profile,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && isAuthorizedToBeta && Hub_IP,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && isAuthorizedToBeta && Quota,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && System_Configuration,
        row.hasOwnProperty("sn") && !_.isEmpty(row['sn']) && row['sn'].substring(0, 2) !== "AM" && Organization_Configuration,
    ].filter(action => action);

    const [selectedAction, setSelectedAction] = useState(deviceActionsList[0]);
    const [ou, setOu] = useState<any>({});
    const [modifyConfigData, setModifyConfigData] = useState<any>();

    const [selectedManageConfigType, setSelectedManageConfigType] = useState<any>(Traffic_Policy);
    const [selectedManageConfigHubIPType, setSelectedManageConfigHubIPType] = useState<any>(Hub_IP_Configure);

    useEffect(() => {
        if (selectedAction == Traffic_Policy || selectedAction == Firewall || selectedAction == WAN_Profile || selectedAction == Hub_IP || selectedAction == Quota) {
            setSelectedManageConfigType(selectedAction)
        } else {
            setSelectedManageConfigType("")
        }

        setModifyConfigData({
            selectedDevice: {
                id: row["deviceId"],
                name: row["deviceName"],
                productType: row["productType"],
                current_EOS_version: row["current_EOS_version"],
                konnectStatus : row["konnect_status"]
            },
            selectedSite: row["k4Id"],
            selectedSiteName: row["name"],
            currentEOSversion: row["current_EOS_version"],
            latestEOSversion: row["latestEOSavailable"],
        })
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        if (actionType == Traffic_Policy || actionType == Firewall || actionType == WAN_Profile || actionType == Hub_IP || actionType == Quota) {
            setSelectedManageConfigType(actionType)
        } else {
            setSelectedManageConfigType("")
        }
        setSelectedAction(actionType);

        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const changeGroup = () => {
        inventoryChangeGroup(row["k4Id"], ou?.id);
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedAction('');
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
        setModifyConfigData({});
        setRow({});
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                            <Grid className="device-configuration-popup">
                                <span className="configurations">Configurations</span>
                                <span className="selected-site-device device-configuration-popup-devicename"  title={`${row['name']} ${row['deviceName'] ? `- ${row['deviceName']}` : ''}`}>{`${row['name']} ${row['deviceName'] ? `- ${row['deviceName']}` : ''}`}</span>
                            </Grid>
                            <Grid><img src={close} title="Close" onClick={handleClose} /></Grid>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content warehouse-edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid className="menuScroll device-border-right inventory-deployed-device-list device-config-pop-config-list-menu" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton className={identifier == selectedAction ? "status-lists selectedAction " : "status-lists"} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => handleChangeActionType(identifier)}>
                                                <Tooltip title={identifier.isParent ? 'Parent' : null}>
                                                    <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid className="menuScroll device-config-pop-config-list-menu-content">
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            <span className="edit_confirm_dialog_sub_title_text"> {confirmDialog.subTitle} </span>
                                            <div className="edit-actions-confirm-btns width-76-percent">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}> Proceed </Button>
                                                </div>
                                            </div>
                                        </div>}
                                        {!_.isEmpty(selectedManageConfigType) && !confirmDialog.isOpen &&
                                            <ManageConfig
                                                selectedDevice={modifyConfigData?.selectedDevice}
                                                selectedSite={modifyConfigData?.selectedSite}
                                                selectedSiteName={modifyConfigData?.selectedSiteName}
                                                selectedRow={modifyConfigData ? modifyConfigData : {}}
                                                onClose={handleClose}
                                                setConfirmDialog={setConfirmDialog}
                                                selectedManageConfigType={selectedManageConfigType}
                                                selectedManageConfigHubIPType={selectedManageConfigHubIPType}
                                            />
                                        }
                                        {
                                            selectedAction == System_Configuration && !confirmDialog.isOpen &&
                                            <SystemConfig selectedDeviceInfo={row} onClose={handleClose}/>
                                        }
                                        {
                                            selectedAction == Organization_Configuration && !confirmDialog.isOpen &&
                                            <OrganizationConfig selectedDeviceInfo={row} onClose={handleClose}/>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer
});

export default withRouter(
    connect(mapStateToProps, {

    })(EditActionsPopup)
);