import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";

const ACTION_TYPES = {
    SET_LOADING: "starlinkPooled/setLoading",
    GET_POOLED_USAGE_PIE: "starlinkPooled/getPooledUsagePie",
    GET_POOLED_USAGE_BAR_CHART: "starlinkPooled/getPooledUsageBarChart",
    GET_POOLED_USAGE_TOP_CARDS: "starlinkPooled/getPooledUsageTopCards",
    GET_POOLED_USAGE_LINE_CHART: "starlinkPooled/getPooledUsageLineChart",
    GET_POOLED_USAGE_TABLE: "starlinkPooled/getPooledUsageTable"
}

const initialState = {
    pooledUsagePie: [],
    pooledBarChart: [],
    pooledTopCards: [],
    pooledLineChart: [],
    pooledTable: [],
    gettingPooledUsagePie: false,
    gettingPooledBarChart: false,
    gettingPooledTopCards: false,
    gettingPooledlineChart: false,
    gettingPooledTable: false
}

export function starlinkPooledReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_POOLED_USAGE_PIE: {
            return {
                ...state,
                pooledUsagePie: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_BAR_CHART: {
            return {
                ...state,
                pooledBarChart: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_TOP_CARDS: {
            return {
                ...state,
                pooledTopCards: action.payload,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_LINE_CHART: {
            return {
                ...state,
                pooledLineChart: action.payload?.data,
            }
        }
        case ACTION_TYPES.GET_POOLED_USAGE_TABLE: {
            return {
                ...state,
                pooledTable: action.payload?.data,
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}

export interface getPooledUsageParameters {
    dpId: string;
    chartStartDate: string;
    chartEndDate: string;
    limit: number;
    offset: number;
    searchText: string;
    sortBy: string;
    sortOrder: string;
    source: string;
}

export const getPooledUsageTable = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTable: true } });
    let _parameters = {
        dpId: parameters.dpId,
        chartStartDate: parameters.chartStartDate,
        chartEndDate: parameters.chartEndDate,
        limit: parameters.limit,
        offset: (parameters.offset - 1) * parameters.limit,
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
    };
    const data = {
        query: parameters.source == 'aggregation_table' ? 'GET_STARLINK_ORGANIZATION_USAGE_AGG_CH' : `GET_STARLINK_ORGANIZATION_USAGE_DIRECT_CH`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_TABLE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTable: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTable: false } });
        });
}

export const getPooledUsagePie = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePie: true } });
    let _parameters = {
        dpId: parameters.dpId,
        chartStartDate: parameters.chartStartDate,
        chartEndDate: parameters.chartEndDate,
        limit: parameters.limit,
        offset: parameters.offset,
        source: parameters?.source
    };
    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAGE_PIE_CH`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_PIE)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePie: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledUsagePie: false } });
        });
}

export const getPooledBarChart = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledBarChart: true } });
    let _parameters = {
        dpId: parameters.dpId,
        chartStartDate: parameters.chartStartDate,
        chartEndDate: parameters.chartEndDate,
        source: parameters.source,
        limit: parameters.limit,
        offset: parameters.offset
    };

    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAGE_STACKED_CHART_CH`,
        named: true,
        format: 'chart',
        groupBy: "organizationName",
        tsColumn: "date",
        series: "chartUsage",
        annotation: "asset_name",
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_BAR_CHART)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledBarChart: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledBarChart: false } });
        });
}

export const getPooledTopCards = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTopCards: true } });
    let _parameters = {
        dpId: parameters.dpId
    };

    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAEGE_TOPCARD`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_TOP_CARDS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTopCards: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledTopCards: false } });
        });
}

export const getPooledLineChart = (parameters: getPooledUsageParameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledLineChart: true } });
    let _parameters = {
        dpId: parameters.dpId
    };

    const data = {
        query: `GET_STARLINK_ORGANIZATION_USAGE_BURNOUT_CH`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_POOLED_USAGE_LINE_CHART)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledLineChart: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingPooledLineChart: false } });
        });
}