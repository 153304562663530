import { SERVICE_FUNCTION_TO_FEATURE } from "../config";
import Chip from '@mui/material/Chip';
import {
  PERMISSIONS, IAM_USER_POLICY_ALL_RESOURCES_KEY, IAM_USER_POLICY_ID_KEY,
  IAM_USER_POLICY_TYPE_KEY, IAM_USER_POLICY_VESSEL_NAME_KEY,
  IAM_USER_POLICY_VESSEL, IAM_USER_POLICY_ALL_RESOURCES_VALUE, USER_OU, USER_PREFERENCES, NEWINTERVALS
} from '../constants/Constants';
import _ from "lodash";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment-timezone";
import { UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE } from "../actions/types";

export const getConfigServiceFunctionLabel = (key: string) => {
  if (SERVICE_FUNCTION_TO_FEATURE && SERVICE_FUNCTION_TO_FEATURE[key]) {
    return SERVICE_FUNCTION_TO_FEATURE[key]
  }
  return null;
}

export const getServiceFunctionResources = (permissions: any[], serviceFunctionConfig: any) => {
  if (permissions && Array.isArray(permissions) && serviceFunctionConfig && serviceFunctionConfig.service && serviceFunctionConfig.function) {
    const _service = permissions.find(o => o.service === serviceFunctionConfig.service);
    if (_service) {
      let _serviceFunction = _service.servicefunctions.find(o => o.function === serviceFunctionConfig.function);
      if (_serviceFunction && _serviceFunction.resources) {
        return _serviceFunction.resources;
      }
    }
  }
  return [];
}

export const checkLoggedInUserAuthorizedToViewPage = (permissionKey: any, serviceFunctionKey: any) => {
  let allVesselsCheck = false;
  let serviceFunctionAvailable = getServiceFunction(permissionKey, serviceFunctionKey, allVesselsCheck);
  if (serviceFunctionAvailable) {
    return true;
  }
  return false;
}

/*
* Returns service function data i.e {"function":serviceFunctionKey,"resources":[]} if exists else returns null
*/
export const getServiceFunction = (permissionKey: any, serviceFunctionKey: any, allVesselsCheck = true) => {
  // get filtered all_resources list
  let filteredAllVessels = allVesselsCheck && getFilteredAllVessels();
  // get permissions from local storage 
  let localStorageData = getDataFromLocalStorage(PERMISSIONS);
  if (localStorageData) {
    // Data is stored as key/value pairs of strings, so parse it
    let parsedData = JSON.parse(localStorageData);
    if (permissionKey && parsedData && Array.isArray(parsedData) && parsedData.length > 0) {
      // get permissionKey data
      let permission = parsedData.find(o => o.service.trim() === permissionKey.trim());
      if (serviceFunctionKey && permission && permission.servicefunctions && Array.isArray(permission.servicefunctions)) {
        // get serviceFunctionKey data
        let serviceFunctionData = permission.servicefunctions.find(o => o.function.trim() === serviceFunctionKey.trim());
        if (allVesselsCheck) {
          return performAllVesselsCheck(serviceFunctionData, filteredAllVessels);
        } else {
          return serviceFunctionData;
        }
      }
    }
  }
  return null;
}

const getFilteredAllVessels = () => {
  // get all_resources from local storage
  let allVessels:any = getDataFromLocalStorage(IAM_USER_POLICY_ALL_RESOURCES_KEY);
  try {
    return JSON.parse(allVessels);
  }
  catch(err) {
    return null;
  }
}

//  To update the site-name after change in topology in local-storage
export const updateLocalSiteName = (siteId: string, siteName: string) => {
  let allVessels = getDataFromLocalStorage(IAM_USER_POLICY_ALL_RESOURCES_KEY);
  if (allVessels) {
    // Data is stored as key/value pairs of strings, so parse it
    let parsedAllVessels = JSON.parse(allVessels);
    if (parsedAllVessels && parsedAllVessels.length > 0) {
      const site = parsedAllVessels.find(res => res.id == siteId);
      if (!site)
        return;
      site.name = siteName;
      setDataToLocalStorage(IAM_USER_POLICY_ALL_RESOURCES_KEY, JSON.stringify(parsedAllVessels));
    }
  }
}

const performAllVesselsCheck = (serviceFunction, filteredAllVessels) => {
  let resources = serviceFunction && serviceFunction.resources;
  if (resources) {
    // check resources has vessel-name: "*" i.e all_resources
    let isAllVessels = resources.find(r => r[IAM_USER_POLICY_VESSEL_NAME_KEY] === IAM_USER_POLICY_ALL_RESOURCES_VALUE);
    if (isAllVessels) {
      serviceFunction.resources = filteredAllVessels;
    } else {
      // consider it as resource if and only if TYPE == VESSEL and ID_KEY,VESSEL_NAME_KEY are present
      serviceFunction.resources = resources.filter(a => a[IAM_USER_POLICY_TYPE_KEY] === IAM_USER_POLICY_VESSEL && a[IAM_USER_POLICY_ID_KEY] && a[IAM_USER_POLICY_VESSEL_NAME_KEY]);
    }
  }
  return serviceFunction;
}

export const getDataFromLocalStorage = (key: any) => {
  return localStorage.getItem(key);
}

export const getDataFromLocalStorageParsed = (key: any) => {
  let _data = localStorage.getItem(key) || "";
  try {
    let _parsed = JSON.parse(_data);
    return _parsed;
  } catch (e) {
    return null;
  }
}

export const setDataToLocalStorage = (key: any, value: any) => {
  return localStorage.setItem(key, value);
}

export const removeDataFromLocalStorage = (key: any) => {
  return localStorage.removeItem(key);
}

export const autoCompleteRenderTags = (value: any, limitTags: number, getTagProps, key) => {
  const numTags = value.length;
  return (
    <>
      {value.slice(0, limitTags).map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          key={index}
          label={option[key]}
        />
      ))}
      {numTags > limitTags && ` +${numTags - limitTags}`}
    </>
  );
}

export const readableBytes = (bytes: any) => {
  let _bytes = bytes;
  if (_bytes <= 0 || isNaN(_bytes)) {
    return "-";
  }
  let i: number = Math.floor(Math.log(_bytes) / Math.log(1024));
  let sizes: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  return (_bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export const readableBytesAsGB = (bytes: any, appendUnit = true) => {
  let _bytes = bytes;
  if (_bytes <= 0) {
    return "-";
  }
  const value = (_bytes / Math.pow(1000, 3)).toFixed(3);
  // const value = (_bytes / Math.pow(1000, 3)) < 1 ?  (_bytes / Math.pow(1000, 3)).toFixed(3) : (_bytes / Math.pow(1000, 3)).toFixed(2);
  return appendUnit ? value + ' GB': value;
}

export const readableBytesAsMB = (bytes: any, appendUnit = true) => {
  let _bytes = bytes;
  if (_bytes <= 0) {
    return "-";
  }
  const value = (_bytes / Math.pow(1000, 2)).toFixed(2);
  return appendUnit ? value + ' MB' : value;
}

export const readableBytesToBytes = (readableBytes: string): number => {
  let bytes = 0;
  let unit = readableBytes?.slice(-2)?.trim();
  let value = readableBytes.slice(0, -2);
  let sizes: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = sizes.indexOf(unit);
  if (index === -1) {
    return bytes;
  }
  bytes = parseFloat(value) * Math.pow(1000, index);
  return bytes;
}

export const readablePeriod = (value: number, unit: string) => {
  let dur = unit === 'M' || unit === 'W' || unit === 'D' ? moment.duration(`P${value}${unit}`) : moment.duration(`PT${value}${unit}`.toUpperCase());
  let humanized = dur.humanize()
  if (humanized === "a minute") {
    return "1 minute"
  }else if (humanized === "an hour") {
    return "1 hour"
  } else if (humanized === "a day") {
    return "1 day"
  } else if (humanized === "a month") {
    return "1 month"
  } else if (humanized === "a year") {
    return "1 year"
  } else {
    return humanized
  };
  // return duration.humanize();
}

export const periodToMinutes = (period: string): number => {
  let periodValue = parseInt(period.substring(0, period.length - 1));
  let periodUnit = period.substring(period.length - 1);
  switch (periodUnit) {
    case "D":
      return periodValue * 24 * 60;
    case "W":
      return periodValue * 7 * 24 * 60;
    case "M":
      return periodValue * 30 * 24 * 60;
    default:
      return periodValue;
  }
}

export const extractEmails = (text: string) => {
  return Array.from(new Set<string>(text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) || []));
}

export const calibrateData = (oldUnit: string, newUnit: string, value: string) => {
  switch (newUnit) {
    case 'MB':
      if (oldUnit === 'GB') {
        return `${Number(value) * 1024}`;
      } else if (oldUnit === 'TB') {
        return `${Number(value) * 1024 * 1024}`;
      }
      break;
    case 'GB':
      if (oldUnit === 'MB') {
        return `${Number(value) / 1024}`;
      } else if (oldUnit === 'TB') {
        return `${Number(value) * 1024}`;
      }
      break;
    case 'TB':
      if (oldUnit === 'MB') {
        return `${Number(value) / 1024 / 1024}`;
      } else if (oldUnit === 'GB') {
        return `${Number(value) / 1024}`;
      }
      break;
    default:
      return value;
  }
  return value
}

export const readableBits = (bytes: any) => {
  let _bytes = bytes;
  if (_bytes <= 0) {
    return "-";
  }
  let i: number = Math.floor(Math.log(_bytes) / Math.log(1024));
  let sizes: string[] = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
  return (_bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}

export const getEncodedURI = (data: any) => {
  if (!data) {
    data = {}
  }
  return Object.keys(data).map(function (k) {
    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
  }).join('&');
}

export const getDecodeURI = (line: any) => {
  if (!line) {
    line = ""
  }
  if (line.startsWith("?")) {
    line = line.substring(1);
  }
  if ('' === line.trim()) {
    return {};
  }
  let _line = decodeURIComponent(line);
  let ouName = new URLSearchParams(window.location.search).get('ouName');
  let k4Ids = new URLSearchParams(window.location.search).get('k4Ids');
  let search =  new URLSearchParams(window.location.search).get('search');
  let key = "";
  let value = "";
  let keyValue = ""
  let newLineObj = {};
  if(ouName && ouName?.includes('&') || k4Ids && k4Ids?.includes('&') || search && search?.includes('&')) {
      let newLine = _line.split('&').forEach((item:any) => {
        if(item.includes('ouName') || !item.includes('=') || item.includes('k4Ids') ||  item.includes('search')) {
            // ignore
        } else {
           keyValue = item.split('=');
           if(keyValue) {
            key = keyValue[0];
            value = keyValue[1];
            newLineObj[key] = value;
           }
        }
      })
      if(ouName) {
        newLineObj['ouName'] = ouName;
      }
      if(k4Ids) {
        newLineObj['k4Ids'] = k4Ids;
      }
      if(search) {
        newLineObj['search'] = search;
      }
    return newLineObj;
  } else {
    return Object.fromEntries(_line.split('&').map(item => item.split('=')));
  }
}

export const strToK4List = (line: any) => {
  if (!line || '' === line.trim()) {
    return {};
  }
  return line.split(",").map((k4id) => {
    let _s = k4id.split(":");
    if (2 == _s.length) {
      return ({ 'id': _s[0], 'vessel-name': _s[1] })
    } else {
      return ({ 'id': '', 'vessel-name': '' })
    }
  });
}

export const strToK4HubList = (line: any) => {
  if (!line || '' === line.trim()) {
    return {};
  }
  return line.split(",").map((k4id) => {
    let _s = k4id.split(":");
    if (2 == _s.length) {
      return ({ 'k4Id': _s[0], 'site_name': _s[1] })
    } else {
      return ({ 'k4Id': '', 'site_name': '' })
    }
  });
}

export const strToK4IdList = (line: any) => {
  if (!line || '' === line.trim()) {
    return {};
  }
  return line.split(",K4-").map((k4id, i) => {
    let _s:any = [];
    if(i == 0) {
      _s = k4id.split(":");
    } else {
      let newk4id = `K4-${k4id}`;
      _s = newk4id.split(":");
    }
    return (2 == _s.length) ? _s[0] : '';
  });
}

export const strToList = (line: any) => {
  if (!line || '' === line.trim()) {
    return [];
  }
  return line.split(",");
}

export const fnRound = (n, d) => {
  return Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
};

export const fnComma = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const queryParamK4Ids = "queryParamK4Ids";
export const redirectToTopolgyTabs = (location,pathName,k4Id,siteName,otherQueryParms,saveRequired,keepOnlyOuNameouIdK4IdsInParams) =>{
  let params = getDecodeURI(location?.search);
  if(saveRequired){
    saveQueryParamK4Ids(params);
  }
  params.k4Ids = k4Id + ":" + siteName;

  if(keepOnlyOuNameouIdK4IdsInParams){
    let p = {};
    p["k4Ids"] = params.k4Ids
    if(params.ouName){
      p["ouName"] = params.ouName
    }
    if(params.ouId){
      p["ouId"] = params.ouId
    }
    params = p
  }

  if(!_.isEmpty(otherQueryParms)){
    params = {...params,...otherQueryParms};
  }
  if(pathName == '/reports/activity') {
    params = {...params, topAppClick: true}
  }

  return { pathname: pathName, search: `?${getEncodedURI(params)}` }
}

export const saveQueryParamK4Ids = (params)=> {
  if (params?.k4Ids && localStorage.getItem(queryParamK4Ids) !== params.k4Ids) {
    localStorage.setItem(queryParamK4Ids, params.k4Ids);
  }else if(!(params?.k4Ids)){
    localStorage.removeItem(queryParamK4Ids)
  }
}

export const removeQueryParamK4Ids = () => {
  if(localStorage.getItem(queryParamK4Ids)){
    localStorage.removeItem(queryParamK4Ids)
  }
}

export function populateForParent(data, parentid, org) {
  let _newitem: any = {
    name: data.name,
    id: data.id,
    _childern: []
  };
  if (data && data.parent_of) {
    for (let child of data.parent_of) {
      let newitem: any = {
        name: child.name,
        id: child.id
      }
      _newitem._childern.push(newitem)
      if (child.parent_of) {
        newitem["_childern"] = [];
        for(let nextChild of child.parent_of){
        populateForParent(nextChild, newitem.id, newitem._childern);
        }
      }
    }
  }
  org.push(_newitem)
  return org
}

export const getUserOu = () => {
  let _userOu = localStorage.getItem(USER_OU);
  let userOu = {}
  if(_userOu){
    try {
      userOu = JSON.parse(_userOu)
    } catch (error) {
      console.log(error)
    }
  }
  return userOu
}

export const getInvitedOuList = (dpList) => {
  let invitedOus: any[] = []
  let _userOu = localStorage.getItem(USER_OU);
  if (_userOu) {
    try {
      let userOu = JSON.parse(_userOu)
      if (!_.isEmpty(userOu) && !_.isEmpty(dpList) && dpList?.dps && Array.isArray(dpList.dps) && dpList.dps.length > 0) {
        for (let i = 0; i < dpList.dps.length; i++) {
          let dp = dpList.dps[i]
          if (userOu?.id && !_.isEmpty(dp) && dp?.id && userOu.id !== dp.id) {
            invitedOus.push({
              "id":dp?.id,
              "name":dp?.name
            })
            getChildOus(dp, invitedOus)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return invitedOus
}

export const getloggedInUserOuandSubOusList = (dpList) => {
  let userOus: any[] = []
  let _userOu = localStorage.getItem(USER_OU);
  if (_userOu) {
    try {
      let userOu = JSON.parse(_userOu)
      if (!_.isEmpty(userOu) && !_.isEmpty(dpList) && dpList?.dps && Array.isArray(dpList.dps) && dpList.dps.length > 0) {
        for (let i = 0; i < dpList.dps.length; i++) {
          let dp = dpList.dps[i]
          if (userOu?.id && !_.isEmpty(dp) && dp?.id && userOu.id == dp.id) {
            userOus.push({
              "id": dp?.id,
              "name": dp?.name
            })
            getChildOus(dp, userOus)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return userOus
}

const getChildOus = (dp, invitedOus) => {
  if (dp?.parent_of && Array.isArray(dp.parent_of) && dp.parent_of.length > 0) {
    let childDps = dp.parent_of;
    for (let i = 0; i < childDps.length; i++) {
      invitedOus.push({
        "id":childDps[i]?.id,
        "name":childDps[i]?.name
      })
      getChildOus(childDps[i], invitedOus)
    }
  }
}

export const checkSelectedOuIsInvitedOu = (selectedOu, invitedOus) => {
  let isSelectedOuIsInvitedOu = false;
  let _userOu = localStorage.getItem(USER_OU);
  if (_userOu && invitedOus.length>0) {
    try {
      let userOu = JSON.parse(_userOu)
      if(!_.isEqual(userOu,selectedOu)){
        for(let i=0; i<invitedOus.length;i++){
          if(_.isEqual(selectedOu,invitedOus[i])){
            isSelectedOuIsInvitedOu = true
            break
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return isSelectedOuIsInvitedOu;
}

export const getOu = (organisations, selectedOu, dpList) => {
  let ou = selectedOu;
  if (organisations.length > 1) {
    let invitedOus = getInvitedOuList(dpList)
    let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(selectedOu, invitedOus)
    if (isSelectedOuIsInvitedOu) {
      let userOu = getUserOu()
      ou = userOu
    }
  }
  return ou
}

export const getOuforReports = (organisations, selectedOu, dpList) => {
  let ou = selectedOu;
  if (organisations.length > 1) {
    let invitedOus = getInvitedOuList(dpList)
    //let isSelectedOuIsInvitedOu = checkSelectedOuIsInvitedOu(selectedOu, invitedOus)
    // if (isSelectedOuIsInvitedOu) {
    //   let userOu = getUserOu()
    //   ou = userOu
    // }
  }
  return ou
}
export const generatePDFfile = (header, tableHeaders, tableData, fileName) => {
  const doc = new jsPDF('l', 'pt', 'a4');
  doc.text(header, 10, 25)
  autoTable(doc, {
    head: [tableHeaders],
    body: tableData,
    theme: "striped",
    startY: 50,
    margin: 10,
    headStyles: {
      fillColor: "#264C86"
    }
  });
  doc.save(fileName);
}

export const convertDateTimeIntoTimezone = (stringDate, timezone, requiredFormat) => {
  let formattedDate = ""
    try {
      timezone = getTimezoneCity(timezone)
      if (!_.isEmpty(stringDate) && !stringDate.startsWith("0001-01-01")) {
        let date = moment.tz(stringDate, 'UTC').tz(timezone).format(requiredFormat)
        if (date.toLocaleLowerCase() != "invalid date") {
            formattedDate = date //.replace(",", "")
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  return formattedDate
}

export const getTimezoneCity = (timezone: string) => {
  // ex: Asia/Colombo
  let city = ""
  if (!_.isEmpty(timezone)) {
    city = timezone.split(" - ")[0]
  }
  return city
}

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const getMinutes = (start, end) => {
  return Math.ceil(((new Date(end)).getTime() - (new Date(start)).getTime()) / 60e3);
}

export const getDateForChart = (data) => {
  if(data?.length > 1) {
    for(let i = 0; i < data?.length; i++) {
      if(data[i]?.data?.length > 1) {
        return false;
      }
    }
  }
  if(data?.length == 1 && data[0]?.data?.length == 1) {
    return true;
  } else if(data?.length > 1 && data[0]?.data?.[0]?.x == data[1]?.data?.[0]?.x) {
    return true;
  } else {
    return false;
  }
}

export const getSingleRecordChartLable = (data, timezone) => {
  return moment(new Date(data[0]?.data?.[0]?.x)).tz(timezone).format('DD-MMM, hh:mm');
}

export const getIntervalCheck = (minutes) => {
  if (minutes > 10080) {
    return true;
  } 
  return false;
}

export const getUserGuide = (pageName: string, domain) => {
  if(domain !== '') {
    window.open(`https://${domain}.scrollhelp.site/kb/${pageName}`);
  } else {
    window.open(`https://kognitive.scrollhelp.site/kb/${pageName}`);
  }
}

export const updateTimePeriod = (dispatch, location) => {
  let start: any = {};
  let end: any = {};
  let params: any = getDecodeURI(location?.search);
  const selectedInterval = params.hasOwnProperty('interval') ? params.interval : '1h';
  let interval:any = {
    duration: 60,
    timeVal: 'minutes'
  }
  for (let i = 0; i < NEWINTERVALS.length; i++) {
    if(selectedInterval == NEWINTERVALS[i].value) {
      interval.duration = NEWINTERVALS[i].duration;
      interval.timeVal = NEWINTERVALS[i].timeVal;
    }
  }
  if (params.hasOwnProperty('interval') && (params.interval == 'customDates' || params.interval == 'MTD')) {
    start = moment(new Date(parseInt(params.startDate)));
    end = moment(new Date(parseInt(params.endDate)));
  } else {
    start = moment(Date.now()).subtract(Number(interval.duration), interval.timeVal).utc();
    end = moment(Date.now()).utc();
  }
  dispatch({ type: UPDATE_NEW_SUMMARY_START_DATE, payload: start.utc() })
  dispatch({ type: UPDATE_NEW_SUMMARY_END_DATE, payload: end.utc() })
  return { startDate: start.valueOf(), endDate: end.valueOf() };
}

export const formatSize = (value) => {
  if (value >= 1000) {
      return (value / 1000).toFixed(2) + ' TB';
  }
  return value.toFixed(2) + ' GB';
};

export const formatDataSizeAsTB = (value) => {
  return (value / 1000).toFixed(2) + ' TB';
};