import { Box, Card, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPooledTopCards } from "./slice";
import _ from "lodash";
import { formatDataSizeAsTB } from "../../utils/util";

const TopCards = (props) => {
    const { selectedOu, pooledTopCards, getPooledTopCards } = props;
    const [topCardsData, setTopCardsData] = useState<any>([]);

    useEffect(() => {
        const payload = {
            dpId: selectedOu?.id
        }
        getPooledTopCards(payload);
    }, [selectedOu])

    useEffect(() => {
        if (!_.isEmpty(pooledTopCards)) {
            const data = pooledTopCards.hasOwnProperty('data') ? pooledTopCards.data : {};
            setTopCardsData(data[0]);
        }
    }, [pooledTopCards])

    return (
        <Grid className="cards" container columns={12} spacing={2}>
            <CardContents title='SUB ORGANIZATIONS' value={topCardsData?.totalOrganizationCount ? topCardsData.totalOrganizationCount : 0} totalUsage={null} totalLimit={null} usagePercentage={null} />
            <CardContents title='USAGE' value={topCardsData?.totalUsage && topCardsData?.totalLimit ? `${formatDataSizeAsTB(topCardsData.totalUsage)} / ${formatDataSizeAsTB(topCardsData.totalLimit)}` : '0 / 0'} totalUsage={topCardsData?.totalUsage} totalLimit={topCardsData?.totalLimit} usagePercentage={topCardsData?.usagePercentage} />
            <CardContents title='SERVICE LINES' value={topCardsData?.totalServiceLines ? topCardsData.totalServiceLines : 0} totalUsage={null} totalLimit={null} usagePercentage={null} />
            <CardContents title='PLANS' value={topCardsData?.totalCustomPlans ? topCardsData.totalCustomPlans : 0} totalUsage={null} totalLimit={null} usagePercentage={null} />
        </Grid>
    )
}

interface CardProps {
    title: string;
    value: any;
    totalUsage: any;
    totalLimit: any;
    usagePercentage: any;
}

const CardContents = (props: CardProps) => {
    const { title, value, totalUsage, totalLimit, usagePercentage } = props;

    return (
        <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className="card-widget" id={'removeBoxShadow'} >
                <Box className="cardHeader cardHeader-serviceLines">
                    <span className="cardTitle">{title}</span>
                </Box>
                <Box className="cardContents">
                    <Box className="cardActive">
                        <div title={value} className="content" style={title != 'USAGE' ? { margin: '12px 0px' } : { margin: '0px' }}>{value}</div>
                    </Box>
                </Box>
                {title == 'USAGE' && <Box sx={{ margin: '10px' }}>
                    <Box sx={{ width: '100%', height: 4, bgcolor: totalUsage > totalLimit ? '#cc3300' : '#c2c7e3', borderRadius: 5, position: 'relative' }} >
                        <Box sx={{ width: `${totalUsage > totalLimit ? totalLimit / totalUsage * 100 : 100}%`, position: 'relative' }}>
                            <Box sx={{ width: `${totalUsage > totalLimit ? 100 : usagePercentage > 100 ? usagePercentage : 100}%`, height: 4, bgcolor: totalUsage > totalLimit ? '#ffcc00' : '#c2c7e3', borderRadius: 5, position: 'absolute' }} />
                            <Box sx={{ width: `${totalUsage > totalLimit ? 100 : usagePercentage < 100 ? usagePercentage : 100}%`, height: 4, bgcolor: '#2F4B82', borderRadius: 5, position: 'absolute' }} />
                        </Box>
                    </Box>
                </Box>
                }
            </Card>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer?.selectedOu,
    pooledTopCards: state?.starlinkPooled?.pooledTopCards
});

export default withRouter(
    connect(mapStateToProps, { getPooledTopCards })(TopCards)
);