import { Fragment, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Alert, Box, Button, Checkbox, Chip, Dialog, FormControl, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Edit } from "@material-ui/icons";
import { Pagination } from "../../Pagination";
import { toast } from "react-toastify";
import { getDecodeURI, getEncodedURI } from '../../../utils/util';
import SortArrows, { SortOrder } from "../../SortArrows";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { getOrganisationUnitList } from "../../../actions/Users/authenticate";
import OrgSelection from "../../OrgSelection";
import AddchartIcon from '@mui/icons-material/Addchart';
import { ACTION_TYPES, createSubOrg, getOrganizationList, topUpChildPool, updateSubPool } from "../slice";
import _ from "lodash";
import { createSubPool, getParentPoolList } from "../slice";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DeleteIcon from '@mui/icons-material/Delete';
import moment, { Moment } from "moment";

const STARLINK_ADMIN_SF = "starlink-admin";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[3], row[5], row[7], row[0], row[8] ? 'Yes' : 'No'])
    })
    return [['Organization', 'Account Type', 'Username', 'Client ID', 'Valid'], ...csvRows]
}

export type AccountSortBy = 'parentPoolName' | 'subPoolName' | 'subPoolSize' | 'subPoolBillingDate' | 'fullName';

type SubPoolItem = {
    poolName: string;
    poolSize: string;
    unit: string;
    startDate: Moment | null;
    duration: string;
    isRecurring: boolean;
    selectedDp: any;
    poolInfo: string;
    assignedBy: string;
    dpId: string;
    data:any;
};

function StarlinkSubOrg(props) {

    const { authReducer, history, location, dpIds, downloadAsCSV, newSummaryServiceLine, parentPoolList, addTopUpToChildPool, topUpChildPool, createSubOrg, createSubPool, updateSubPool, getOrganizationList, organizationLists, organizationListCount, getParentPoolList } = props;
    const _q = getDecodeURI(location?.search)
    const [selectedOu, setSelectedOu] = useState(_q?.ouId)
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [editPlanDialog, setEditPlanDialog] = useState<boolean>(false);
    const ouId = _q.hasOwnProperty("ouId") ? _q.ouId : ""
    const [topUpPopUp, setTopUpPopup] = useState<boolean>(false);
    const [accountForTopup, setAccountForTopup] = useState<any>(null);
    const [topUpValue, setTopUpValue] = useState<any>(0);
    const [topUp, setTopUp,] = useState<any>(0);
    const [usageType, setUsageType] = useState<any>("tb")
    const [showNote, setShowNote] = useState<boolean>(true);
    const [openCreateOrg, setOpenCreateOrg] = useState<boolean>(false);
    const [selectedDp, setSelectedDp] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [disableActions, setDisableActions] = useState<boolean>(false);
    const [newSubOrgName, setNewSubOrgName] = useState<string>('');

    const [organizationList, setOrganizationList] = useState([])
    const [newSubOrgNameValidationError, setNewSubOrgNameValidationError] = useState<string>('');
    const [parentPools, setParentPools] = useState<any>([]);
    const [currentSubPool, setCurrentSubPool] = useState<SubPoolItem>({
        poolName: '',
        poolSize: '',
        unit: 'tb',
        startDate: null,
        duration: 'monthly',
        isRecurring: true,
        selectedDp: null,
        poolInfo: '',
        assignedBy: '',
        dpId: '',
        data: null,
    });
    const [selectedServiceAccountId, setSelectedServiceAccountId] = useState('');
    const [selectedServiceAccount, setSelectedServiceAccount] = useState({ parentPoolId: '', credentialId: '', accountNumber: '' });
    const [paginationParams, setPaginationParams] = useState<{
        page: number;
        size: number;
        sortBy: AccountSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as AccountSortBy || 'fullName',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'asc',
        search: queryParams.get('search') || '',
    });

    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState<any>([]);
    const [error, setError] = useState('');
    const [topUpVal, setTopUpVal] = useState('');
    const [entries, setEntries] = useState([{ usagePercentage: '70', type: 'sendNotification' }]);

    const handleValueChange = (index, field, value) => {
        const newEntries = [...entries];
        newEntries[index][field] = value;
        setEntries(newEntries);
    };

    const handleAddEntry = () => {
        setEntries([...entries, { usagePercentage: '', type: 'sendNotification' }]);
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const handleAddEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && emailInput) {
            event.preventDefault();
            if (validateEmail(emailInput)) {
                setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
                setEmailInput('');
                setError('');
            } else {
                setError('Invalid email address');
            }
        }
    };

    const handleRemoveEntry = (index) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmails(emails.filter(email => email !== emailToDelete));
    };

    const getFirstDayOfMonth = () => {
        return moment().startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0);
    };

    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();

    const handleNewSubOrgSave = async () => {
        setDisableActions(true);
        setErrorMessage('');

        if (!_.isEmpty(selectedDp)) {
            let res: any = await createSubOrg({
                DPName: newSubOrgName,
                DPFullName: newSubOrgName,
                parentName: selectedDp.name
            })
            let _level = selectedDp.level + 1;
            if (res?.status === 1) {
                setSelectedDp({
                    id: res?.data?.data?.dpId,
                    name: newSubOrgName,
                    level: _level,
                    hasChildren: false,
                })
            } else if (res?.response?.data?.message) {
                setErrorMessage(res?.response?.data?.message);
            } else {
                setErrorMessage('Failed to create Sub-Organization');
            }
        }
        setDisableActions(false);
        setOpenCreateOrg(false);
    }

    const getOrgs = () => {
        getOrganizationList({
            dpId: ouId,
            searchText: paginationParams.search,
            sortBy: paginationParams.sortBy,
            sortOrder: paginationParams.sortOrder,
            limit: paginationParams.size,
            offset: (paginationParams.page - 1) * paginationParams.size
        })
    }

    const handleChangeNewSubOrgName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewSubOrgName(event.target.value);
        if (event.target.value.match(/[^A-Za-z0-9\s]/)) {
            setNewSubOrgNameValidationError('Only alphanumeric characters are allowed');
        } else {
            setNewSubOrgNameValidationError('');
        }
    }

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPaginationParams({ ...paginationParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setPaginationParams({ ...paginationParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: AccountSortBy, sortOrder: SortOrder) => {
        setPaginationParams({
            ...paginationParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }


    useEffect(() => {
        setParentPools(parentPoolList)
    }, [parentPoolList])


    useEffect(() => {
        updateQueryParams();
        getParentPoolList({
            dpIds: dpIds,
            searchText: "",
            sortBy: "poolName",
            sortOrder: "desc",
            limit: 20,
            offset: 0,
            childPools: false
        })

        getOrganizationList({
            dpId: ouId,
            searchText: paginationParams.search,
            sortBy: paginationParams.sortBy,
            sortOrder: paginationParams.sortOrder,
            limit: paginationParams.size,
            offset: (paginationParams.page - 1) * paginationParams.size
        })
    }, [dpIds, paginationParams, newSummaryServiceLine]);


    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'service-account') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status[0] === null) {
                    return;
                }
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (rows.length) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))])
                            , {
                                formatters: {
                                    0: 'ESC-COMMA'
                                }
                            }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'service-account',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: paginationParams.search,
                sortBy: paginationParams.sortBy,
                sortOrder: paginationParams.sortOrder,
                limit: 0,
                offset: 0
            },
            queryName: 'GET_SERVICE_ACCOUNTS',
        }
        downloadAsCSV(params);
    }

    const handleSubPoolChange = (field: keyof SubPoolItem, value: any) => {
        setCurrentSubPool({
            ...currentSubPool,
            [field]: value,
        });
    };

    useEffect(()=>{
        setTopUpVal(currentSubPool?.data?.topups?.reduce((acc, curr) => acc + (curr.topup || 0), 0))
    },[currentSubPool])

    const handleCreateSubPool = async () => {

        let params = {
            parentPoolId: selectedServiceAccount?.parentPoolId,
            credentialId: selectedServiceAccount?.credentialId,
            dpId: accountForTopup?.dpId,
            assignedBy: accountForTopup?.dpId,
            poolName: currentSubPool?.poolName,
            poolSize: currentSubPool?.poolSize,
            unit: currentSubPool?.unit,
            startDate: getFirstDayOfMonth(),
            duration: currentSubPool?.duration,
            isRecurring: currentSubPool?.isRecurring,
            poolInfo: currentSubPool?.poolInfo,
            notificationMethods: ["email"],
            quotaActions: entries,
            usersToNotify: emails,
            accountNumber: selectedServiceAccount?.accountNumber
        }

        let res = await createSubPool(params);
        if (res?.success) {
            toast.success(`${currentSubPool?.poolName} assigned successfully`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })

        } else if (res?.response?.data?.message) {
            toast.error(`Unable to assign ${currentSubPool?.poolName} `, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
        setEditPlanDialog(false)
        setAccountForTopup(null)
        getOrgs()
        clearData()
    }

    const clearData = () => {
        setCurrentSubPool({
            poolName: '',
            poolSize: '',
            unit: 'tb',
            startDate: null,
            duration: 'monthly',
            isRecurring: true,
            selectedDp: null,
            poolInfo: '',
            assignedBy: '',
            dpId: '',
            data:null
        });
        setEmailInput('')
        setEmails([]);
        setEntries([{ usagePercentage: '70', type: 'sendNotification' }]);
        setSelectedDp([])
        setEditPlanDialog(false);
    }

    const handleEditSubPool = async () => {

        let params = {
            parentPoolId: selectedServiceAccount?.parentPoolId,
            credentialId: selectedServiceAccount?.credentialId,
            childPoolId: accountForTopup?.subPoolDetails?.id,
            dpId: accountForTopup?.dpId,
            assignedBy: accountForTopup?.dpId,
            poolName: currentSubPool?.poolName,
            poolSize: currentSubPool?.poolSize,
            unit: currentSubPool?.unit,
            startDate: getFirstDayOfMonth(),
            duration: currentSubPool?.duration,
            isRecurring: currentSubPool?.isRecurring,
            poolInfo: currentSubPool?.poolInfo,
            notificationMethods: ["email"],
            quotaActions: entries,
            usersToNotify: emails,
            accountNumber: selectedServiceAccount?.accountNumber
        }

        let res = await updateSubPool(params);
        if (res?.success) {
            toast.success(`${currentSubPool?.poolName} update successfully`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })

        } else if (res?.response?.data?.message) {
            toast.error(`Unable to update ${currentSubPool?.poolName} - ${res?.response?.data?.message} `, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
        setEditPlanDialog(false)
        setAccountForTopup(null)
        getOrgs()
        clearData()
    }

    useEffect(() => {
        if (organizationLists) {
            setOrganizationList(organizationLists)
        }
    }, [organizationLists])

    const getAccountType = (account) => {
        if (account?.credentialDetails?.accountType === 'service_account_client' && account?.parentPoolDetails?.id) {
            return "Pooled Account"
        } else if (account?.credentialDetails?.accountType === 'service_account_client') {
            return "Service Account"
        } else if (account?.credentialDetails?.accountType === 'credential') {
            return "Web Account"
        } else {
            return account.type
        }
    }


    const handleTopUp = async (data) => {

        let params ={
            organizationId: selectedOu ? selectedOu : "",
            accountNumber: data?.subPoolDetails?.accountNumber,
            unit: usageType,
            topup: topUpValue,
            credentialId: "",
            serviceLineNumber:"",
            parentPoolId: data?.parentPoolDetails?.id,
            childPoolId: data?.subPoolDetails?.id
        }
        await topUpChildPool(params)

        setAccountForTopup(null);
        setTopUpPopup(false);
        dispatch({ type: ACTION_TYPES.ADD_TOPUP_TO_CHILD_POOL, payload: {} })

        getOrganizationList({
            dpId: ouId,
            searchText: paginationParams.search,
            sortBy: paginationParams.sortBy,
            sortOrder: paginationParams.sortOrder,
            limit: paginationParams.size,
            offset: (paginationParams.page - 1) * paginationParams.size
        })
    }

    useEffect(()=>{
        if (!_.isEmpty(addTopUpToChildPool)) {
            toast.success(`Top Up added successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })
        }
        setTopUpValue(0)

    },[addTopUpToChildPool])

    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x add-srcoll-starlink-subtab-pages' }}>
            {/* FILTERS */}
            <Grid item xs={12} sm={12} md={12} lg={12} paddingBottom={1}>
                {showNote ?
                    <Alert
                        severity="info"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowNote(!showNote);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <Typography component="div" className="starlink--base--font_09x starlink--base--text_start">
                            {/* <Typography component="span" className="starlink--base--font_09x starlink--base--font_bold">"Sub Organization" </Typography> */}
                            Configurations made in this page are for internal purposes only
                        </Typography>
                    </Alert> : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mb={2} classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        SUB ORGANIZATION
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>

                    <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{ root: "input-box-user-ter" }}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    {/* <Grid container  xs={12} display="flex" justifyContent="flex-end" style={{ marginTop: '10px' }}> */}
                    <Grid item xs={2} sm={2} md={2} lg={2}>

                        <Button variant="contained" color="primary" className="starlink--button_contained--primary" onClick={() => { setOpenCreateOrg(true) }}>
                            Create Sub Org
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        {/* {1 > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>} */}
                    </Grid>   </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={organizationListCount} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {/* <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="parentPoolName" onChange={handleChangeSorting}> */}
                                <span className="font-wt-900">SUB ORGANIZATION</span>
                                {/* </SortArrows> */}
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">ACCOUNT TYPE</span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">SERVICE ACCOUNT</span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">POOL </span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">SIZE</span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">USAGE (%)</span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">BILLING DATE </span>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">ACTIONS</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            organizationList?.length > 0 ? organizationList.map((organisation: any, index) => {
                                return <TableRow
                                    key={organisation?.organizationName}
                                >
                                    <TableCell>
                                        {organisation?.organizationName}
                                    </TableCell>
                                    <TableCell>
                                        {getAccountType(organisation)}
                                    </TableCell>
                                    <TableCell>
                                        {organisation?.parentPoolDetails?.poolName}
                                    </TableCell>
                                    <TableCell>
                                        {organisation?.subPoolDetails?.poolName}
                                    </TableCell>
                                    <TableCell>
                                        {organisation?.subPoolDetails?.poolSize} {organisation?.subPoolDetails?.unit.toUpperCase()}
                                    </TableCell>
                                    <TableCell>
                                        {/* - */}
                                    </TableCell>
                                    <TableCell>
                                        {organisation?.subPoolDetails ? moment(organisation?.subPoolDetails?.billingDate).format("MM/DD/YYYY") : ""}
                                    </TableCell>

                                    <TableCell>
                                        <Tooltip title="Top Up">
                                            <span>
                                                <IconButton
                                                    disabled={!organisation?.subPoolDetails}
                                                    size='small'
                                                    onClick={() => {
                                                        setTopUpPopup(true);
                                                        setAccountForTopup(organisation);
                                                    }}
                                                >
                                                    <AddchartIcon fontSize="small" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>

                                        <Tooltip title={organisation?.subPoolDetails ? "Edit Sub Pool" : "Assign Sub Pool to Organization"}>
                                            <span>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        setEditPlanDialog(true);
                                                        setAccountForTopup(organisation);

                                                        if (organisation.subPoolDetails) {
                                                            setCurrentSubPool({
                                                                poolName: organisation.subPoolDetails?.poolName,
                                                                poolSize: organisation.subPoolDetails?.poolSize,
                                                                unit: organisation.subPoolDetails?.unit,
                                                                startDate: moment(organisation.subPoolDetails?.startDate),
                                                                duration: organisation.subPoolDetails?.duration,
                                                                isRecurring: organisation.subPoolDetails?.isRecurring,
                                                                selectedDp: null,
                                                                poolInfo: organisation.subPoolDetails?.poolInfo,
                                                                data:organisation,
                                                                assignedBy: '',
                                                                dpId: '',
                                                            });
                                                            setSelectedServiceAccount({
                                                                credentialId: organisation.subPoolDetails.credentialId || '',
                                                                parentPoolId: organisation.subPoolDetails.parentPoolId || '',
                                                                accountNumber: organisation.subPoolDetails.accountNumber || '',
                                                            });
                                                            setSelectedServiceAccountId(organisation.subPoolDetails.accountNumber || '');

                                                            setEmails(organisation?.quota?.usersToNotify);
                                                            setEntries(organisation?.quota?.quotaActions?.map((q: any) => ({ usagePercentage: q?.usagePercentage, type: q?.type })));
                                                        }
                                                    }}
                                                >
                                                    <Edit fontSize="small" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </TableCell>

                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={6} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={editPlanDialog} onClose={() => { setEditPlanDialog(false); setAccountForTopup(null); }} aria-labelledby="service_account--dialog" maxWidth="lg" fullWidth>
                <Grid container spacing={1} style={{ padding: '2em 0em 0em 2em' }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            component="div"
                            align="center"
                            style={{ fontWeight: 'bold', color: "#424141" }}
                        >
                            {accountForTopup?.subPoolDetails ? "Modify" : "Create"} Sub Pool ({accountForTopup ? accountForTopup?.organizationName : ""})
                        </Typography>
                    </Grid>
                    <Grid container xs={12} mt={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography fontSize={"0.8rem"}>Note: The Sub Organization Pool Account is created within Kognitive Cloud only, and used to allocate Starlink Pool Account data to an Organization</Typography>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" mt={2} mb={1}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Pool Name</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Service Account</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography variant="subtitle1">Pool Size</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="subtitle1">Unit</Typography>
                        </Grid>
                        {accountForTopup?.subPoolDetails ? 
                        <Grid item xs={1.5}>
                            <Typography variant="subtitle1">TopUp (TBs)</Typography>
                        </Grid> : null}
                        <Grid item xs={1.5}>
                            <Typography variant="subtitle1">Start Date</Typography>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Typography variant="subtitle1">Duration</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="subtitle1">Recurring</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} >
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                disabled={accountForTopup?.subPoolDetails}
                                value={currentSubPool.poolName}
                                onChange={(e) => handleSubPoolChange('poolName', e.target.value)}
                                size="small"
                                placeholder="For eg. Pool 500 GB"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                fullWidth
                                value={selectedServiceAccountId}
                                disabled={accountForTopup?.subPoolDetails}
                                onChange={(e) => {
                                    const accountId = e.target.value;
                                    setSelectedServiceAccountId(accountId);

                                    const selectedAccount = parentPools.find(pool => pool.accountNumber === accountId);

                                    if (selectedAccount) {
                                        setSelectedServiceAccount(selectedAccount);
                                    } else {
                                        setSelectedServiceAccount({ parentPoolId: '', credentialId: '', accountNumber: '' });
                                    }
                                }}
                                displayEmpty
                                size="small"
                            >
                                {parentPools?.map((pool) => (
                                    <MenuItem key={pool.parentPoolId} value={pool.accountNumber}>{pool.poolName}</MenuItem>
                                ))}
                            </Select>

                        </Grid>
                        <Grid item xs={1.5}>
                            <TextField
                                disabled={accountForTopup?.subPoolDetails}
                                type='number'
                                fullWidth
                                value={currentSubPool.poolSize}
                                onChange={(e) => handleSubPoolChange('poolSize', e.target.value)}
                                size="small"
                                placeholder="For eg. 500"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Select
                                fullWidth
                                disabled={accountForTopup?.subPoolDetails}
                                value={currentSubPool.unit}
                                onChange={(e) => handleSubPoolChange('unit', e.target.value)}
                                displayEmpty
                                size="small"
                            >
                                {/* <MenuItem value="gb">GB</MenuItem> */}
                                <MenuItem value="tb">TB</MenuItem>
                            </Select>
                        </Grid>
                        {accountForTopup?.subPoolDetails ? 
                        <Grid item xs={1.5}>
                            <TextField
                                disabled={accountForTopup?.subPoolDetails}
                                type='number'
                                fullWidth
                                value={topUpVal}
                                // onChange={(e) => handleSubPoolChange('poolSize', e.target.value)}
                                size="small"
                                // placeholder="For eg. 500"
                            />
                        </Grid>
                        : null}
                        <Grid item xs={1.5}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={getFirstDayOfMonth()}
                                    onChange={(newValue: any) => handleSubPoolChange('startDate', newValue)}
                                    disabled={accountForTopup?.subPoolDetails}
                                    slots={{
                                        textField: TextField,
                                    }}
                                    slotProps={{
                                        textField: {
                                            InputProps: {
                                                style: {
                                                    height: '40px',
                                                    padding: '0 14px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                                readOnly: true,
                                            },
                                            InputLabelProps: {
                                                style: {
                                                    lineHeight: 'normal',
                                                },
                                            },
                                        },
                                    }}
                                    readOnly
                                    disableOpenPicker
                                />
                            </LocalizationProvider>


                        </Grid>
                        <Grid item xs={1.5}>
                            <Select
                                fullWidth
                                disabled={accountForTopup?.subPoolDetails}
                                size="small"
                                value={currentSubPool.duration}
                                onChange={(e) => handleSubPoolChange('duration', e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="monthly">Monthly</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={1}>
                            <Checkbox
                                // disabled={accountForTopup?.subPoolDetails}
                                checked={currentSubPool.isRecurring}
                                onChange={(e) => handleSubPoolChange('isRecurring', e.target.checked)}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={1}>
                            <Typography>
                                Pool Info
                            </Typography>
                        </Grid>
                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={9}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                value={currentSubPool?.poolInfo}
                                onChange={(e) => handleSubPoolChange('poolInfo', e.target.value)}
                                size="small"
                                placeholder="For eg. Some Description about pool"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '10px' }}>
                        {entries && entries?.map((entry, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                            >
                                <Grid item xs={0.6}></Grid>
                                <Grid item xs={1}>
                                    <Typography>Quota Action </Typography>
                                </Grid>
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            label="% used"
                                            variant="outlined"
                                            value={entry.usagePercentage}
                                            type="number"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value) && (value === "" || parseFloat(value) > 0 )) {
                                                    handleValueChange(index, 'usagePercentage', e.target.value)
                                                }
                                            }
                                            }
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: "0" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={0.25}></Grid>
                                <Grid item xs={4}>
                                    <Select
                                        label="Action"
                                        fullWidth
                                        size="small"
                                        value={entry.type}
                                        onChange={(e) => handleValueChange(index, 'type', e.target.value)}
                                    >
                                        <MenuItem value="sendNotification">Send Notification</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={0.5} display="flex" alignItems="center">
                                    {entries.length > 1 && (
                                        <IconButton
                                            onClick={() => handleRemoveEntry(index)}
                                            color="secondary"
                                        >
                                            <DeleteIcon sx={{ color: 'red' }} fontSize="small" />
                                        </IconButton>
                                    )}
                                    {index === entries.length - 1 && (
                                        <IconButton onClick={handleAddEntry} color="primary">
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                        <Grid item xs={0.75}></Grid>
                        <Grid item xs={1}>
                            <Typography>
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={0.25}></Grid>
                        <Grid item xs={6.4}>
                            <Box>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        padding: '8px',
                                        borderRadius: '10px',
                                        border: '1px solid #c2d3eb',
                                        boxShadow: 'none',
                                    }}
                                >
                                    {emails.map((email, index) => (
                                        <Chip
                                            key={index}
                                            label={email?.email}
                                            onDelete={() => handleDeleteEmail(email)}
                                            sx={{ margin: '4px' }}
                                        />
                                    ))}
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        error={!!error}
                                        placeholder="Press Enter to add email"
                                        helperText={error}
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                        onKeyDown={handleAddEmail}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                padding: '0 4px',
                                            },
                                        }}
                                        sx={{
                                            width: 'auto',
                                            flexGrow: 1,
                                            minWidth: '120px',
                                            '& .MuiInputBase-root': {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: '8px 4px',
                                                flex: 1,
                                            },
                                        }}
                                    />
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }} style={{ paddingRight: '30px' }} >
                    <Button variant="outlined" className='starlink--button--cancel'
                        onClick={() => {
                            clearData();
                            setAccountForTopup(null);
                            setSelectedServiceAccount({ parentPoolId: '', credentialId: '', accountNumber: '' })
                            setSelectedServiceAccountId('')

                        }} >
                        Cancel</Button>
                    <Button variant="contained" className='starlink--button_contained--primary'
                        disabled={currentSubPool?.poolName === '' || currentSubPool?.poolSize === '' ||
                            currentSubPool?.poolInfo === ''
                        }
                        onClick={() => { accountForTopup && accountForTopup?.subPoolDetails ? handleEditSubPool() : handleCreateSubPool() }} >
                        {accountForTopup && accountForTopup?.subPoolDetails ? "Update" : "Assign"}
                    </Button>
                </Grid>
            </Dialog>

            <Dialog
                open={openCreateOrg}
                onClose={() => { setOpenCreateOrg(false); }}
                aria-labelledby="top_up--dialog"
                maxWidth="sm"
                fullWidth
            >
                <div style={{ margin: "30px" }}>
                    <Grid container spacing={2} justifyContent={"center"} >
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                component="div"
                                align="center"
                                style={{ fontWeight: 'bold', color: "#424141" }}
                            >
                                Create Sub Organisation
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                <OrgSelection label={"Parent Organization"} value={selectedDp} onChange={handleChangeDp} disabled={disableActions} />
                            </Grid>
                            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                                {
                                    <TextField
                                        disabled={disableActions}
                                        fullWidth
                                        size="small"
                                        id="starlink--service_account--sub_org_name"
                                        label="Sub-Organization Name"
                                        value={newSubOrgName}
                                        onChange={handleChangeNewSubOrgName}
                                        error={newSubOrgNameValidationError.length > 0}
                                    />
                                }
                                <FormHelperText error={newSubOrgNameValidationError?.length > 0}>
                                    <Typography component="div" className="starlink--base--text_center starlink--base--font_08x">
                                        {newSubOrgNameValidationError}
                                    </Typography>
                                </FormHelperText>
                            </Grid>
                            <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                                {
                                    <Fragment>
                                        <Button size="small" variant="outlined" className='starlink--button--cancel' onClick={() => { setOpenCreateOrg(false) }} disabled={disableActions}>Cancel</Button>
                                        <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={handleNewSubOrgSave} disabled={disableActions || newSubOrgNameValidationError.length > 0 || newSubOrgName.length === 0 || selectedDp === null}>Create</Button>
                                    </Fragment>}
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            </Dialog>

            <Dialog
                open={topUpPopUp}
                onClose={() => { setTopUpPopup(false); setAccountForTopup(null); }}
                aria-labelledby="top_up--dialog"
                maxWidth="sm"
                fullWidth
            >
                <div style={{ margin: "30px" }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                component="div"
                                align="center"
                                style={{ fontWeight: 'bold', color: "#424141" }}
                            >
                                Top Up ({accountForTopup ? accountForTopup?.organizationName : ""})
                            </Typography>
                        </Grid>
                        <Grid container xs={12} m={1} >
                            <Typography fontSize={"0.8rem"} textAlign={'center'}>Note: The Sub Organization Pool Account can have additional data added for that usage-duration only, for the specific Starlink Pool Account.</Typography>
                        </Grid>
                        <Grid container xs={12} mt={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={4}><Typography>Current Pool Size : {accountForTopup?.subPoolDetails?.poolSize} {accountForTopup?.subPoolDetails?.unit?.toUpperCase()} </Typography></Grid>
                        </Grid>
                        <Grid container xs={12} m={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={1}><Typography>Top Up</Typography></Grid>
                            <Grid item xs={4}>
                                <TextField
                                    type="number"
                                    fullWidth
                                    value={topUpValue === 0 ? "" : topUpValue} 
                                    onChange={(e) => { setTopUpValue(e.target.value) }}
                                    size="small"
                                /></Grid>
                            <Grid item xs={1.5}>
                                <Select
                                    fullWidth
                                    size="small"
                                    value={usageType}
                                    onChange={(e) => { setUsageType(e.target.value) }}
                                    displayEmpty
                                >
                                    <MenuItem value="gb">GB</MenuItem>
                                    <MenuItem value="tb">TB</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--center' }}>
                        <Button variant="outlined" className='starlink--button--cancel' onClick={() => { setTopUpPopup(false); setAccountForTopup(null); }} >Cancel</Button>
                        <Button variant="contained" className='starlink--button_contained--primary' onClick={() => { handleTopUp(accountForTopup) }} >Save</Button>
                    </Grid>
                </div>
            </Dialog>

        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    organizationLists: state?.starlinkAdmin?.organizationList,
    organizationListCount: state?.starlinkAdmin?.organizationListCount,
    parentPoolList: state.starlinkAdmin.parentPoolList,
    addTopUpToChildPool: state?.starlinkAdmin?.addTopUpToChildPool,
});

export default withRouter(
    connect(mapStateToProps, {
        getOrganisationUnitList,
        downloadAsCSV,
        createSubOrg,
        createSubPool,
        updateSubPool,
        getOrganizationList,
        getParentPoolList,
        topUpChildPool
    })(StarlinkSubOrg)
);