
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Asset, AssetsTableProps, ServiceLine } from "../types";
import { Avatar, Box, Checkbox, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import SortArrows from "../../SortArrows";
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { STARLINK_DATAUSAGE_CATEGORIES } from "../../../UserScreen/Starlink/types";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../../utils/util";
import { DEFAULT_DATE_FORMAT } from "../../../utils/constants";
import { getQuotaAvailablityAlert } from "../slice";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';



const AssetsTable = (props: AssetsTableProps) => {
    const { authReducer, assets, assetUsageSeries, startDate, endDate, sortOrder, sortBy, selectedAssets, onChange, onChangeSorting, onEditServiceLineQuota, onEditServiceLineAvailability, isReadOnlyUser, getQuotaAvailablityAlert, quotaAvailablityAlerts, location, history } = props;
   
    const [currentAsset,setCurrentAsset] = useState({asset_display_name:'',asset_number:'', action:''})
   
    const parseBillingDayOfMonth = (billingDayOfMonth: number | null) => {
        if (!billingDayOfMonth) return '';
        const lastDigit = billingDayOfMonth % 10;
        if (lastDigit === 1) return `${billingDayOfMonth}st`;
        if (lastDigit === 2) return `${billingDayOfMonth}nd`;
        if (lastDigit === 3) return `${billingDayOfMonth}rd`;
        return `${billingDayOfMonth}th`;
    }

    useEffect(()=>{
        if(!_.isEmpty(currentAsset) && !_.isEmpty(quotaAvailablityAlerts)  && quotaAvailablityAlerts.length > 0 && quotaAvailablityAlerts[0]?.quota_alert && currentAsset.action === 'quota') {
        let _serviceLine: ServiceLine = {
            serviceLineNumber: currentAsset.asset_number,
            nickname: currentAsset.asset_display_name || '',
            quotaAlert: quotaAvailablityAlerts[0].quota_alert || {
                serviceLineNumber: currentAsset.asset_number,
                alertingThresholds: [],
                notificationMethods: [],
                usersToNotify: []
            },
        }
        onEditServiceLineQuota(_serviceLine);
    }

    if(!_.isEmpty(currentAsset) && !_.isEmpty(quotaAvailablityAlerts)  && quotaAvailablityAlerts.length > 0 && quotaAvailablityAlerts[0]?.availability_alert && currentAsset.action === 'availablity') {
       
        let _serviceLine: ServiceLine = {
            serviceLineNumber: currentAsset.asset_number,
            nickname: currentAsset.asset_display_name || '',
            availabilityAlert: quotaAvailablityAlerts[0].availability_alert || {
                serviceLineNumber: currentAsset.asset_number,
                alertingThreshold: "15m",
                notificationMethods: [],
                usersToNotify: []
            },
        }
        onEditServiceLineAvailability(_serviceLine);
    }
    },[quotaAvailablityAlerts])

    const LetterCircle = ({ letter, bgColor, color }: { letter: string, bgColor: string, color: string }) => (
        <Avatar sx={{ backgroundColor: bgColor, color, width: 20, height: 20 }}>
        <Typography style={{color:color}} fontSize={"10px"} >{letter}</Typography> 
        </Avatar>
    );
    

    const IconCircle = ({ icon, bgColor }: { icon: React.ReactNode, bgColor: string }) => (
        <Avatar sx={{ backgroundColor: bgColor, width: 20, height: 20 }}>
        {icon}
        </Avatar>
    );

    const handleClickSelect = (column: string, value: string | {dpName: string, dpId: string}) => {
        const params: any = getDecodeURI(location.search);
        if (column === 'serviceLine') {
            params.serviceLineAccess = value;
        } else if (typeof value === 'object') {
            params.ouName = value.dpName;
            params.ouId = value.dpId;
        }
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };
    

    return (
        <TableContainer component={Paper} className="starlink--table_container">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--usage">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'asset_display_name'} onChange={onChangeSorting}>
                                <span className="font-wt-900">SERVICE LINE</span>
                            </SortArrows>
                        </TableCell>
                        <TableCell >
                             <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'asset_display_name'} onChange={onChangeSorting}>
                                        <span className="font-wt-900">STATUS</span>
                                    </SortArrows>
                                </TableCell>
                        <TableCell>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'account_dp_name'} onChange={onChangeSorting}>
                                <span className="font-wt-900">ORGANIZATION</span>
                            </SortArrows>
                        </TableCell>
                        <TableCell>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'dp_name'} onChange={onChangeSorting}>
                                <span className="font-wt-900">SUB ORGANIZATION</span>
                            </SortArrows>
                        </TableCell>
                        <TableCell>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'subscription_line_usage_limit_gb'} onChange={onChangeSorting}>
                                <span className="font-wt-900"> SERVICE PLAN</span>
                            </SortArrows>
                        </TableCell>
                        <TableCell>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'curr_billing_cycle_usage_percent'} onChange={onChangeSorting}>
                                <span className="font-wt-900">BILLING USAGE</span>
                            </SortArrows>
                        </TableCell>
                        <TableCell>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'billing_day_of_month'} onChange={onChangeSorting}>
                                <span className="font-wt-900">BILLING DATE</span>
                            </SortArrows>
                        </TableCell>
                        <TableCell>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'chart_usage'} onChange={onChangeSorting}>
                                <Box>
                                    <Box>
                                        <span className="font-wt-900">CHART USAGE</span>
                                    </Box>
                                    <Box>
                                        <span className="font-wt-900">({convertDateTimeIntoTimezone(startDate.toString(), authReducer.userTimezone, DEFAULT_DATE_FORMAT)} - {convertDateTimeIntoTimezone(endDate.toString(), authReducer.userTimezone, DEFAULT_DATE_FORMAT)})</span>
                                    </Box>
                                </Box>
                            </SortArrows>
                        </TableCell>
                        <TableCell width={'5%'}>
                            <SortArrows sortBy={sortBy} sortOrder={sortOrder} field={'k4SiteName'} onChange={onChangeSorting}>
                                <span className="font-wt-900">SITE</span>
                            </SortArrows>
                        </TableCell>
                        <TableCell width={'5%'}>ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    assets?.length > 0 ? assets?.map((asset) => (
                            <TableRow key={asset.user_terminal_id}>
                                <TableCell >
                                    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                          <Grid>
                                              <Table size="small" className="starlink--tooltip">
                                                  <TableBody>
                                                      <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Service Line Number
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset.asset_number}
                                                          </TableCell>
                                                      </TableRow>
                                                      <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Account Number
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset.account_number}
                                                          </TableCell>
                                                      </TableRow>
                                                      <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Account Name
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset.account_name}
                                                          </TableCell>
                                                      </TableRow>
                                                      {/* <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Billing Day of Month
                                                          </TableCell>
                                                          <TableCell>
                                                              {parseBillingDayOfMonth(asset.billing_day_of_month)}
                                                          </TableCell>
                                                      </TableRow> */}
                                                      {/* <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Starlink ID
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset.user_terminal_id}
                                                          </TableCell>
                                                      </TableRow> */}
                                                      <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              KIT Number
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset.user_terminal_serial_number}
                                                          </TableCell>
                                                      </TableRow>
                                                      {/* <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Active
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset.active ? 'Yes' : 'No'}
                                                          </TableCell>
                                                      </TableRow> */}
                                                       <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Custom Plan
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset?.is_custom_plan === "true" ? 'Yes' : 'No'}
                                                          </TableCell>
                                                      </TableRow>
                                                      <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Mobile Priority
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset?.opted_in !== "" ? 'Enabled' : 'Disabled'}
                                                          </TableCell>
                                                      </TableRow>
                                                      <TableRow>
                                                          <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>
                                                              Subscription Status
                                                          </TableCell>
                                                          <TableCell>
                                                              {asset?.is_service_line_paused === "false" ? 'Active' : 'Inactive'}
                                                          </TableCell>
                                                      </TableRow>
                                                  </TableBody>
                                              </Table>
                                          </Grid>
                                      }>
                                        <span id="selectedClick-column" onClick={() => handleClickSelect('serviceLine', asset.asset_number)}>
                                            {asset.asset_display_name ? asset.asset_display_name : asset.account_name ? asset.account_name : asset.asset_number}
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </TableCell>
                            <TableCell >
                            <div style={{ display: "flex" }}>
                                <div>
                                    {asset?.opted_in ? (
                                        <Tooltip
                                            title="Mobile Priority: Enabled"
                                            arrow
                                        >
                                            <div><LetterCircle letter="MP" bgColor="blue" color="white" /></div>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Mobile Priority: Disabled"
                                            arrow
                                        >
                                            <div><LetterCircle letter="MP" bgColor="lightgray" color="black" /></div>
                                        </Tooltip>
                                    )}
                                </div>
                                <div style={{ marginLeft: "4px" }}>
                                    {asset?.is_service_line_paused === "false" ? (
                                        <Tooltip
                                            title="Subscription Status: Active"
                                            arrow
                                        >
                                            <div><IconCircle icon={<PlayArrowIcon />} bgColor="green" /></div>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="Subscription Status: Inactive"
                                            arrow
                                        >
                                            <div><IconCircle icon={<PauseIcon />} bgColor="gray" /></div>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>

                            </TableCell>
                            <TableCell id="selectedClick-column" onClick={() => handleClickSelect('org', { dpName: asset.account_dp_name, dpId: asset.account_dp_id })}>
                                {asset.account_dp_name}
                                </TableCell>
                                <TableCell id="selectedClick-column" onClick={() => handleClickSelect('org', {dpName: asset.dp_name, dpId: asset.dp_id})}>
                                    {asset.dp_name}
                                </TableCell>
                                <TableCell>{asset.subscription_line_description}</TableCell>
                                 <TableCell>
                                    {asset.curr_billing_cycle_usage != "" && asset.curr_billing_cycle_usage ? <Grid display={'flex'} alignItems={'center'}>
                                        {asset?.curr_billing_cycle_usage_split && asset?.curr_billing_cycle_usage_split !== "" && asset?.curr_billing_cycle_usage_split?.length > 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                            <Grid>
                                                <Table size="small">
                                                <TableBody>
                                                <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Priority GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')) && JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"'))[0]?.usagePriorityGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Opt In Priority GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')) && JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"'))[0]?.usageOptInPriorityGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Standard GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')) && JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"'))[0]?.usageStandardGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Non Billable GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')) && JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.curr_billing_cycle_usage_split.replace(/'/g, '"'))[0]?.usageNonBillableGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        }>
                                            <div style={{color: Number(asset.curr_billing_cycle_usage_percent) >= 100 ? "red" : ""}}>{asset.curr_billing_cycle_usage}GB ({parseFloat(asset.curr_billing_cycle_usage_percent).toFixed(2)}%)</div>
                                        </Tooltip> : <div style={{color: Number(asset.curr_billing_cycle_usage_percent) >= 100 ? "red" : ""}}>{asset.curr_billing_cycle_usage}GB ({parseFloat(asset.curr_billing_cycle_usage_percent).toFixed(2)}%)</div>}
                                       
                                    </Grid> : `0 (${parseFloat(asset.curr_billing_cycle_usage_percent ? asset.curr_billing_cycle_usage_percent : "0").toFixed(2)}%)`}
                                </TableCell> 
                               <TableCell>
                                    {asset.current_billing_start_date ? convertDateTimeIntoTimezone(moment(asset.current_billing_start_date).add(1, 'months').toString(), authReducer.userTimezone, DEFAULT_DATE_FORMAT) : ''}
                                </TableCell>
                               <TableCell>
                                    {asset.chart_usage ? <Grid display={'flex'} alignItems={'center'}>
                                        {asset?.curr_billing_cycle_usage_split?.length > 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                            <Grid>
                                                {/* Table Removed */}
                                            </Grid>
                                        }>
                                            <Box>
                                                {`${asset.chart_usage} GB`}
                                            </Box>
                                        </Tooltip> : `${asset.chart_usage} GB`}
                                        {asset?.chart_usage_split?.length > 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                            <Grid>
                                                <Table size="small">
                                                <TableBody>
                                                <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Priority GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.chart_usage_split.replace(/'/g, '"')) && JSON.parse(asset.chart_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.chart_usage_split.replace(/'/g, '"'))[0]?.usagePriorityGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Opt In Priority GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.chart_usage_split.replace(/'/g, '"')) && JSON.parse(asset.chart_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.chart_usage_split.replace(/'/g, '"'))[0]?.usageOptInPriorityGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Standard GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.chart_usage_split.replace(/'/g, '"')) && JSON.parse(asset.chart_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.chart_usage_split.replace(/'/g, '"'))[0]?.usageStandardGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Non Billable GB'}</TableCell>
                                                        <TableCell>{JSON.parse(asset.chart_usage_split.replace(/'/g, '"')) && JSON.parse(asset.chart_usage_split.replace(/'/g, '"')).length > 0 ? JSON.parse(asset.chart_usage_split.replace(/'/g, '"'))[0]?.usageNonBillableGB : '0'}  GB</TableCell>
                                                    </TableRow>
                                                    
                                                </TableBody>
                                                </Table>
                                            </Grid>
                                        }>
                                            <IconButton>
                                                <CallSplitIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip> : null}
                                    </Grid> : null}
                                </TableCell>
                                  
                                <TableCell>
                                    {asset.k4SiteName} 
                                </TableCell>
                                <TableCell>
                                    <Grid classes={{ root: 'starlink--base--flex--align--center starlink--base--flex--justify--center' }}>
                                       
                                        {!isReadOnlyUser &&
                                            asset?.account_type === 'enterprise' && <Tooltip title="Set/Edit Availability Alert">
                                                <IconButton size="small" onClick={async() => {
                                                      let quotaAlert = await getQuotaAvailablityAlert({serviceLineNumber:[asset.asset_number]})
                                                      setCurrentAsset({
                                                          asset_number: asset.asset_number,
                                                          asset_display_name: asset.asset_display_name,
                                                          action:"availablity"
                                                      })
                                                }}>
                                                    <EditNotificationsIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                       {/* {!isReadOnlyUser &&  <Tooltip title="Set/Edit Quota Alert">
                                            <IconButton size="small" onClick={async() => {
                                                let quotaAlert = await getQuotaAvailablityAlert({serviceLineNumber:[asset.asset_number]})
                                                setCurrentAsset({
                                                    asset_number: asset.asset_number,
                                                    asset_display_name: asset.asset_display_name,
                                                    action:"quota"
                                                })
                                              
                                            }}>
                                                <DataUsageIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>} */}
                                    </Grid>
                                </TableCell> 
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={10} align='center'>
                                No data available
                            </TableCell>
                        </TableRow>
                    }
                </TableBody> 
            </Table>
        </TableContainer>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    assets: state.starlinkReports.assets,
    assetUsageSeries: state.starlinkReports.assetUsage,
    quotaAvailablityAlerts:  state.starlinkReports.quotaAvailablityAlerts,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
    connect(mapStateToProps, {
        getQuotaAvailablityAlert
    })(AssetsTable)
);
