import { inventoryDeviceHistory } from '../../actions/Users/authenticateInventory';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, TableHead } from '@mui/material';
import close from "../../asset/image/close.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import './inventoryDeployed.css';
import _ from 'lodash';
import moment from 'moment';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../utils/constants';

const DeviceHistoryPopup = (props) => {
    const { inventoryDeviceHistory, deviceHistoryLoading, deviceHistoryData, open, setOpen, deviceIdSelected, inventoryId } = props;

    const [deviceHistory, setDeviceHistory] = useState<any>([]);

    useEffect(() => {
        inventoryDeviceHistory(inventoryId);
    }, [])

    useEffect(() => {
        if (!_.isEmpty(deviceHistoryData) && deviceHistoryData.status == 1) {
            const data = deviceHistoryData.hasOwnProperty('data') ? deviceHistoryData.data : [];
            setDeviceHistory(data);
        }
    }, [deviceHistoryData])

    return (
        <div className="Form menuScroll">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{ borderRadius: '8px', width: '1000px' }}
                className='config-deploy-inventory-popup menuScroll device-history-popup'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>{`Inventory History (${deviceIdSelected})`}</span>
                        <img src={close} title="Close" onClick={() => setOpen(false)} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content device-history-content">
                            <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                                <Table aria-label="simple sticky table" stickyHeader>
                                    <TableHead className="usageReports-tableHead">
                                        <TableRow className="UsageReports-tableRow login-tableRow">
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th dh-width-350px">Change</TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th">IP Address</TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th dh-fullname">Created By</TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th">Created At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {0 != deviceHistory.length && !deviceHistoryLoading ? (
                                        <TableBody className="tableBody usage-tableBody">
                                            {
                                                deviceHistory && deviceHistory.map((row, i) =>
                                                    <TableRow key={i} className="loginHistory-tableRow">
                                                        <TableCell className="login-left-align">{row.change}</TableCell>
                                                        <TableCell className="login-left-align">{row.ipAddress}</TableCell>
                                                        <TableCell className="login-left-align">{row.fullName ? row.fullName : row.createdBy}</TableCell>
                                                        <TableCell className='login-left-align'>{moment(row.createdAt).format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    deviceHistoryData: state.authReducer?.deviceHistory,
    deviceHistoryLoading: state.authReducer?.setDeviceHistoryLoading,
});

export default withRouter(
    connect(mapStateToProps, { inventoryDeviceHistory })(DeviceHistoryPopup)
);