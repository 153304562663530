import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { Box, Button, Dialog, FormControl, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";

import { Search } from '@mui/icons-material';
import { Edit, Delete } from "@material-ui/icons";
import CircleIcon from '@mui/icons-material/Circle';
import moment from "moment";

import { Pagination } from "../../Pagination";
import ServiceAccountDialog from "../ServiceAccountDialog";

import './ServiceAccounts.css';
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI } from '../../../utils/util';

import { createServiceAccount, deleteServiceAccount, getServiceAccounts, updateServiceAccount, topUpParentPool, ACTION_TYPES } from "../slice";
import SortArrows, { SortOrder } from "../../SortArrows";
import { ServiceAccount } from "../types";
import { toast } from "react-toastify";
import { DeleteConfirmationDialog } from "../DeleteConfirm";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import ServiceAccountWorkflowDialog from "../ServiceAccountWorkflowDialog";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import AddchartIcon from '@mui/icons-material/Addchart';

import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import PooledDialog from "./pooledDialog";
import _ from "lodash";

const STARLINK_ADMIN_SF = "starlink-admin";
const STARLINK_FULL_SF = "starlink-full";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
const starlinkFullService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["service"];
const starlinkFullFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["function"];

const getAccountType = (account) => {
    if (account.type === 'service_account_client' && account?.isPooledAccount) {
        return "Pooled Account"
    } else if (account.type === 'service_account_client') {
        return "Service Account"
    } else if (account.type === 'credential') {
        return "Web Account"
    } else {
        return account.type
    }
}

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        const serviceAccounts = row[6];
        const formattedDetails = Array.isArray(serviceAccounts)
            ? serviceAccounts.map((serviceAcc: any, index) => {
                // Build the details string
                let detailsString = `${index + 1}. Account Name: ${serviceAcc.accountName}; Account Type: ${serviceAcc?.isPooledAccount ? "Pooled Account" : "Standard Account"}; Account Number: ${serviceAcc.accountNumber}`;
                // Conditionally append Size field
                if (serviceAcc.poolSize) {
                    detailsString += `; Size: ${serviceAcc.poolSize}${serviceAcc.unit ? serviceAcc.unit : ''}`;
                }

                return detailsString;
            }).join('\n')
            : '';
        const escapeCsvValue = (value: any) => {
            if (value === null || value === undefined) {
                return '""'; // Handle null and undefined values
            }
            const strValue = String(value);
            // Check if the value contains special characters (comma, newline, double quotes)
            const needsQuoting = strValue.includes(',') || strValue.includes('\n') || strValue.includes('"');
            // Escape double quotes by doubling them and wrap the value in double quotes
            const escapedValue = strValue.replace(/"/g, '""');
            return needsQuoting ? `"${escapedValue}"` : escapedValue;
        };
        csvRows.push([escapeCsvValue(row[4].replace(',', ';')), escapeCsvValue(getAccountType({ type: row[7] })), escapeCsvValue(row[9]), escapeCsvValue(row[0]), escapeCsvValue(row[10] ? 'Yes' : 'No'), escapeCsvValue(formattedDetails)])
    })
    return [['Organization', 'Account Type', 'Username', 'Client ID', 'Valid', 'Service Accounts'], ...csvRows]
}
interface ServiceAccountsProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    serviceAccounts: any[];
    totalServiceAccounts: number;
    getServiceAccounts: (parameters: any) => void;
    createServiceAccount: (parameters: ServiceAccount, abortSignal: AbortSignal | undefined) => Promise<any>;
    updateServiceAccount: (parameters: ServiceAccount) => Promise<any>;
    deleteServiceAccount: (id: string) => Promise<any>;
    downloadAsCSV: (parameters: any) => void;
    topUpParentPool: (parameters: any) => void;
    newSummaryServiceLine: any;
    addTopUpToParentPool:any;
}

export type AccountSortBy = 'user_name' | 'client_id' | 'valid' | 'dpName' | 'type';

function ServiceAccounts(props: ServiceAccountsProps) {

    const { authReducer, errorReducer, history, location, dpIds, serviceAccounts, totalServiceAccounts, addTopUpToParentPool, topUpParentPool, getServiceAccounts, createServiceAccount, updateServiceAccount, deleteServiceAccount, downloadAsCSV, newSummaryServiceLine } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const params = getDecodeURI(location.search)
    const [selectedOu, setSelectedOu] = useState(params?.ouId)
    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [paginationParams, setPaginationParams] = useState<{
        page: number;
        size: number;
        sortBy: AccountSortBy;
        sortOrder: SortOrder;
        search: string;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as AccountSortBy || 'client_id',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || '',
    });
    const [serviceAccountData, setServiceAccountData] = useState({ serviceAccount: [], index: -1, type: null, credential: null, orgId: null })
    const [showPooledDialog, setShowPooledDialog] = useState(false)
    const [openServiceAccount, setOpenServiceAccount] = useState<boolean>(false)
    const [usageType, setUsageType] = useState<any>("tb")
    const [openServiceAccountDialog, setOpenServiceAccountDialog] = useState<boolean>(false);
    const [serviceAccountToUpdate, setServiceAccountToUpdate] = useState<any>(null);
    const [toDeleteServiceAcount, setToDeleteServiceAcount] = useState<ServiceAccount | null>(null);
    const [requesAbortController, setRequesAbortController] = useState<AbortController | null>(null);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [topUpPopUp, setTopUpPopup] = useState<boolean>(false);
    const [accountForTopup, setAccountForTopup] = useState<any>(null);
    const [topUpValue, setTopUpValue] = useState<any>(0);
    const [selectedAccount, setSelectedAccount] = useState<any>({})

    const dispatch = useDispatch();

    const refreshServiceAccountTable = (samePage: boolean = false) => {
        let _page = paginationParams.page;
        setPaginationParams({
            ...paginationParams,
            page: samePage ? _page : 1
        });
    }

    const handleOpenServiceAccountDialog = () => {
        setRequesAbortController(new AbortController());
        setOpenServiceAccountDialog(true);
    }

    const handleCloseServiceAccountDialog = (refresh: boolean = false) => {
        setOpenServiceAccountDialog(false);
        setServiceAccountToUpdate(null);
        if (refresh) {
            refreshServiceAccountTable();
        }
    }

    const handleSaveServiceAccount = async (serviceAccount: ServiceAccount) => {
        if (serviceAccountToUpdate) {
            await updateServiceAccount(serviceAccount);
            refreshServiceAccountTable()
        } else {
            await createServiceAccount(serviceAccount, requesAbortController?.signal);
            refreshServiceAccountTable()
        }
        setOpenServiceAccountDialog(false);
        setServiceAccountToUpdate(null);
    }

    const handleDeleteServiceAcount = (serviceAccount: ServiceAccount) => {
        setToDeleteServiceAcount(serviceAccount);
    }

    const handleDeleteConfirm = async () => {
        if (toDeleteServiceAcount) {
            let _toDeleteServiceAcount = toDeleteServiceAcount
            setToDeleteServiceAcount(null);
            let res = await deleteServiceAccount(_toDeleteServiceAcount.id);
            if (res?.success) {
                refreshServiceAccountTable();
            } else {
                toast.error("Failed to delete serviceAccount", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        }
        setToDeleteServiceAcount(null);
    }

    const handleDeleteCancel = () => {
        setToDeleteServiceAcount(null);
    }


    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }


    const handleSearch = (value: string) => {
        setPaginationParams({
            ...paginationParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPaginationParams({ ...paginationParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setPaginationParams({ ...paginationParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: AccountSortBy, sortOrder: SortOrder) => {
        setPaginationParams({
            ...paginationParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', paginationParams.page.toString())
        queryParams.set('size', paginationParams.size.toString())
        queryParams.set('sortBy', paginationParams.sortBy)
        queryParams.set('sortOrder', paginationParams.sortOrder)
        queryParams.set('search', paginationParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
        getServiceAccounts({
            dpIds,
            searchText: paginationParams.search,
            sortBy: paginationParams.sortBy,
            sortOrder: paginationParams.sortOrder,
            page: paginationParams.page,
            limit: paginationParams.size
        });
    }, [dpIds, paginationParams, newSummaryServiceLine]);

    const handleEdit = (serviceAccount: ServiceAccount) => {
        setServiceAccountToUpdate(serviceAccount);
    }

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'service-account') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status[0] === null) {
                    return;
                }
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (rows.length) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))])
                            , {
                                formatters: {
                                    0: 'ESC-COMMA'
                                }
                            }
                        );
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'service-account',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: paginationParams.search,
                sortBy: paginationParams.sortBy,
                sortOrder: paginationParams.sortOrder,
                limit: totalServiceAccounts,
                offset: 0
            },
            queryName: 'GET_SERVICE_ACCOUNTS',
        }
        downloadAsCSV(params);
    }

    useEffect(()=>{
        setServiceAccountData({ serviceAccount: serviceAccounts[serviceAccountData?.index]?.serviceAccounts, 
            index: serviceAccountData?.index,
             type:  serviceAccounts[serviceAccountData?.index]?.type, 
            credential:  serviceAccounts[serviceAccountData?.index]?.id,
        orgId: serviceAccounts[serviceAccountData?.index]?.dpId })
    },[serviceAccounts])
    
    const handleTopUp = async (serviceAccount) => {
        let params ={
            organizationId: selectedOu ? selectedOu : "",
            accountNumber: serviceAccount?.accountNumber,
            unit: usageType,
            topup: topUpValue,
            credentialId: "",
            serviceLineNumber:"",
            parentPoolId: serviceAccount?.parentPoolId
        }
        await topUpParentPool(params)
        
        setTopUpPopup(false);
        setAccountForTopup(null);
        dispatch({ type: ACTION_TYPES.ADD_TOPUP_TO_PARENT_POOL, payload: {} })
        getServiceAccounts({
            dpIds,
            searchText: paginationParams.search,
            sortBy: paginationParams.sortBy,
            sortOrder: paginationParams.sortOrder,
            page: paginationParams.page,
            limit: paginationParams.size
        })
    }

    useEffect(()=>{
        if (!_.isEmpty(addTopUpToParentPool)) {
            toast.success(`Top Up added successfully.`, {
                position: toast.POSITION.BOTTOM_LEFT,
            })
        }
        setTopUpValue(0)
    },[addTopUpToParentPool])

    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x add-srcoll-starlink-subtab-pages' }}>
            {/* FILTERS */}
            <Grid item xs={12} sm={12} md={12} lg={12} mb={2} classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        SERVICE ACCOUNTS
                    </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Type something"
                                classes={{ root: "input-box-service" }}
                                size="small"
                                value={searchText}
                                onChange={handleOnSearchChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "serachBar",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchText && <SearchIcon
                                                className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                            />}
                                            {searchText && <CloseIcon
                                                className="cursorPointer input-search-icons"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    {/* link service account button */}
                    <Button variant="contained" disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} color="primary" className="starlink--button_contained--primary" onClick={handleOpenServiceAccountDialog}>
                        Link Service Account
                    </Button>
                    {totalServiceAccounts > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}
                </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={totalServiceAccounts} onPageChange={handleChangePage} page={paginationParams.page} rowsPerPage={paginationParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>

                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                {/* <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="type" onChange={handleChangeSorting}> */}
                                <span className="font-wt-900">ACCOUNT TYPE</span>
                                {/* </SortArrows> */}
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="client_id" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">CLIENT ID</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={paginationParams.sortBy} sortOrder={paginationParams.sortOrder} field="valid" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">VALID</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <span className="font-wt-900">ACTIONS</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            serviceAccounts?.length > 0 ? serviceAccounts.map((serviceAccount, index) => {
                                return <><TableRow
                                    key={serviceAccount.id}
                                >
                                    <TableCell className={openServiceAccount ? "accordian-expand no-padding" : 'accordian-expand remove-border-btm'} id={index != 0 ? 'hub-add-padding-top' : ''}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => {
                                                setOpenServiceAccount(!openServiceAccount);
                                                setServiceAccountData({
                                                    serviceAccount: serviceAccount?.serviceAccounts,
                                                    index: index,
                                                    type: serviceAccount?.type,
                                                    credential: serviceAccount?.id,
                                                    orgId: serviceAccount?.dpId
                                                })
                                            }}
                                        >
                                            {openServiceAccount && serviceAccountData?.index === index ? <KeyboardArrowUpIcon className="accordian-arrow" /> : <KeyboardArrowRightIcon className="accordian-arrow" />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        {serviceAccount.dpName}
                                    </TableCell>
                                    <TableCell>
                                        {getAccountType(serviceAccount)}
                                    </TableCell>
                                    <TableCell>
                                        {serviceAccount.clientId ? serviceAccount.clientId : '-'}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={serviceAccount.valid ? 'Valid' : 'Invalid'}>
                                            <Grid classes={{ root: 'starlink--base--flex--column starlink--base--flex--align--center' }}>
                                                <CircleIcon className="starlink--circle_icon starlink--service_accounts--valid_status" fontSize="small" sx={{ color: serviceAccount.valid ? 'green' : 'red' }} /> {serviceAccount.valid ? 'Yes' : 'No'}
                                            </Grid>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {/* edit */}
                                        <Tooltip title="Edit"><IconButton disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} size='small' onClick={() => { handleEdit(serviceAccount) }}>
                                            <Edit fontSize="small" />
                                        </IconButton></Tooltip>
                                        {/* delete */}
                                        <Tooltip title="Delete"><IconButton disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} size='small' onClick={() => { handleDeleteServiceAcount(serviceAccount) }}>
                                            <Delete fontSize="small" />
                                        </IconButton></Tooltip>
                                    </TableCell>
                                </TableRow>
                                    {openServiceAccount && index === serviceAccountData?.index ? <>
                                        <TableRow
                                            key={serviceAccount.id}
                                        >
                                            <TableCell>

                                            </TableCell>
                                            <TableCell colSpan={6}>
                                                <TableContainer sx={{ margin: "20px 0px" }} component={Paper} className="starlink--table_container">
                                                    <Table sx={{ minWidth: 550 }} size="small" aria-label="starlink--service_accounts">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>ACCOUNT NAME</TableCell>
                                                                <TableCell>ACCOUNT TYPE</TableCell>
                                                                <TableCell>ACCOUNT NUMBER</TableCell>
                                                                <TableCell>SIZE</TableCell>
                                                                <TableCell>TOP UP</TableCell>
                                                                <TableCell>ACTIONS</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {serviceAccountData?.serviceAccount && serviceAccountData?.serviceAccount?.length > 0 &&
                                                                serviceAccountData?.serviceAccount.map((account: any, ind) => {
                                                                    return (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Tooltip
                                                                                    classes={{ tooltip: 'starlink--tooltip' }}
                                                                                    title={account?.isPooledAccount ?
                                                                                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                                                            <Table size="small">
                                                                                                <TableBody>
                                                                                                    <TableRow>
                                                                                                        <TableCell>Name </TableCell>
                                                                                                        <TableCell>{account?.accountName}</TableCell>
                                                                                                    </TableRow>
                                                                                                    <TableRow>
                                                                                                        <TableCell>Size </TableCell>
                                                                                                        <TableCell>{account?.poolSize} {account?.unit?.toUpperCase()}</TableCell>
                                                                                                    </TableRow>
                                                                                                    <TableRow>
                                                                                                        <TableCell>Start</TableCell>
                                                                                                        <TableCell>{moment(account?.startDate).format("MM/DD/YYYY")}</TableCell>
                                                                                                    </TableRow>
                                                                                                    <TableRow>
                                                                                                        <TableCell>End</TableCell>
                                                                                                        <TableCell>{moment(account?.endDate).format("MM/DD/YYYY")}</TableCell>
                                                                                                    </TableRow>
                                                                                                    <TableRow>
                                                                                                        <TableCell>Recurring</TableCell>
                                                                                                        <TableCell>{account?.isRecurring ? "Yes" : "No"}</TableCell>
                                                                                                    </TableRow>
                                                                                                    <TableRow>
                                                                                                        <TableCell>Plan Info</TableCell>
                                                                                                        <TableCell>{account?.poolInfo}</TableCell>
                                                                                                    </TableRow>
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </div> : null
                                                                                    }
                                                                                >
                                                                                    <span>{account?.accountName?.length > 18 ? account?.accountName.slice(0, 18) + "..." : account?.accountName}</span>
                                                                                </Tooltip></TableCell>
                                                                            <TableCell>{account?.isPooledAccount ? "Pooled Account" : "Standard Account"}</TableCell>
                                                                            <TableCell>{account?.accountNumber}</TableCell>
                                                                            <TableCell>{account?.isPooledAccount ? account?.poolSize : "-"} {account?.isPooledAccount ? account?.unit?.toUpperCase() : ""}</TableCell>
                                                                            <TableCell>{account?.isPooledAccount ? account?.topups?.reduce((acc, curr) => acc + (curr.topup || 0), 0) : "-"} {account?.isPooledAccount && account?.topups?.reduce((acc, curr) => acc + (curr.topup || 0), 0) > 0 ? "TB" : ""}</TableCell>
                                                                            <TableCell>
                                                                                <Tooltip title="Top Up">
                                                                                    <span>
                                                                                        <IconButton
                                                                                            disabled={!account?.isPooledAccount}
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                setTopUpPopup(true);
                                                                                                setAccountForTopup(account);
                                                                                            }}
                                                                                        >
                                                                                            <AddchartIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </Tooltip>

                                                                                <Tooltip title={serviceAccountData?.type === "service_account_client" && account?.isPooledAccount ? "Edit Pool" : "Create Pool"}>
                                                                                    <span>
                                                                                        <IconButton
                                                                                            disabled={!(serviceAccountData?.type === "service_account_client")}
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                setShowPooledDialog(true);
                                                                                                setSelectedAccount(account);
                                                                                               
                                                                                            }}
                                                                                        >
                                                                                            <Edit fontSize="small" />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </TableCell>

                                                                        </TableRow>
                                                                    )
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TableCell>
                                        </TableRow>
                                    </> : null} </>
                            }) : <TableRow>
                                <TableCell colSpan={6} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {/* DELETE SERVICE ACCOUNT CONFIRMATION */}
            <DeleteConfirmationDialog open={toDeleteServiceAcount != null} itemName="Service Account" itemCaption={toDeleteServiceAcount ? `${toDeleteServiceAcount.dpName} : ${toDeleteServiceAcount.type === 'service_account_client' ? toDeleteServiceAcount.clientId : toDeleteServiceAcount.userName}` : ''} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} />

            {/* SERVICE ACCOUNT EDIT DIALOG */}
            <ServiceAccountDialog open={serviceAccountToUpdate !== null} serviceAccount={serviceAccountToUpdate} onClose={handleCloseServiceAccountDialog} onSave={handleSaveServiceAccount} />

            {/* SERVICE ACCOUNT WORKFLOW DIALOG */}
            <ServiceAccountWorkflowDialog open={openServiceAccountDialog} onClose={handleCloseServiceAccountDialog} onSave={handleSaveServiceAccount} />

            {/* SHOW POOL DIALOG */}
            <PooledDialog open={showPooledDialog}
                selectedAccount={selectedAccount}
                accountDp={serviceAccountData && serviceAccountData?.orgId}
                accountCredential={serviceAccountData && serviceAccountData?.credential}
                onClose={() => {
                    setShowPooledDialog(false);
                    setSelectedAccount(null)
                    getServiceAccounts({
                        dpIds,
                        searchText: paginationParams.search,
                        sortBy: paginationParams.sortBy,
                        sortOrder: paginationParams.sortOrder,
                        page: paginationParams.page,
                        limit: paginationParams.size
                    })
                }} onSave={handleSaveServiceAccount} />

            <Dialog
                open={topUpPopUp}
                onClose={() => { setTopUpPopup(false); setAccountForTopup(null); }}
                aria-labelledby="top_up--dialog"
                maxWidth="sm"
                fullWidth
            >
                <div style={{ margin: "30px" }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                component="div"
                                align="center"
                                style={{ fontWeight: 'bold', color: "#424141" }}
                            >
                                Top Up ({accountForTopup ? accountForTopup?.accountName : ""})
                            </Typography>
                        </Grid>
                        <Grid container xs={12} mt={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography fontSize={"0.8rem"}>Note: The top up done here is only for internal purpose and has no bearing on starlink.com portal</Typography>
                        </Grid>
                        <Grid container xs={12} mt={2} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={4}><Typography>Current Pool Size : {accountForTopup?.poolSize} {accountForTopup?.unit?.toUpperCase()}</Typography></Grid>
                        </Grid>
                        <Grid container xs={12} m={1} spacing={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={1}><Typography>Top Up</Typography></Grid>
                            <Grid item xs={4}>
                                <TextField
                                    type="number"
                                    fullWidth
                                    value={topUpValue === 0 ? "" : topUpValue} 
                                    onChange={(e) => { setTopUpValue(e.target.value) }}
                                    size="small"
                                /></Grid>
                            <Grid item xs={1.5}>
                                <Select
                                    fullWidth
                                    size="small"
                                    value={usageType}
                                    onChange={(e) => { setUsageType(e.target.value) }}
                                    displayEmpty
                                >
                                    {/* <MenuItem value="gb">GB</MenuItem> */}
                                    <MenuItem value="tb">TB</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--center' }}>
                        <Button variant="outlined" className='starlink--button--cancel' onClick={() => { setTopUpPopup(false); setAccountForTopup(null); }} >Cancel</Button>
                        <Button variant="contained" className='starlink--button_contained--primary' onClick={() => { handleTopUp(accountForTopup);  }} >Save</Button>
                    </Grid>
                </div>
            </Dialog>
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    serviceAccounts: state.starlinkAdmin.serviceAccounts,
    totalServiceAccounts: state.starlinkAdmin.totalServiceAccounts,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    addTopUpToParentPool: state?.starlinkAdmin?.addTopUpToParentPool,
});

export default withRouter(
    connect(mapStateToProps, {
        getServiceAccounts,
        createServiceAccount,
        updateServiceAccount,
        deleteServiceAccount,
        downloadAsCSV,
        topUpParentPool
    })(ServiceAccounts)
);