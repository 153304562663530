import { combineReducers } from "redux";
import authReducer from "./authReducer.tsx";
import errorReducer from "./errorReducer.tsx";
import {remoteConnectionReducer} from "../components/RemoteConnections/remoteConnectionSlice.tsx";
import products from "../vis/react-app/src/reducers/reducers";
import { alertsDashboardReducer } from "../components/AlertsDashboard/slice";
import { quotaManagementReducer } from "../UserScreen/QuotaManagement/slice";
import { StarlinkReportsReducer } from "../components/StarlinkReports/slice";
import { starlinkDashboardReducer } from "../components/StarlinkDashboard/slice";
import { starlinkCloudReducer } from "../UserScreen/Starlink/slice";
import { starlinkAdminReducer } from "../components/StarlinkAdmin/slice";
import { starlinkToSReducer } from "../components/StarlinkToS/slice";
import { starlinkAnalyticsReducer } from "../components/StarlinkAnalytics/slice";
import { starlinkQuotaReducer } from "../components/StarlinkQuota/slice";
import { starlinkPooledReducer } from "../components/StarlinkPooled/slice"

export default combineReducers({
    authReducer: authReducer,
    errorReducer: errorReducer,
    remoteConnection: remoteConnectionReducer,
    alertsDashboard: alertsDashboardReducer,
    quotaManager: quotaManagementReducer,
    starlinkCloud: starlinkCloudReducer,
    starlinkDashboard: starlinkDashboardReducer,
    starlinkReports: StarlinkReportsReducer,
    starlinkAdmin: starlinkAdminReducer,
    starlinkToS: starlinkToSReducer,
    starlinkAnalytics: starlinkAnalyticsReducer,
    starlinkQuota: starlinkQuotaReducer,
    starlinkPooled: starlinkPooledReducer,
    products: products
});
