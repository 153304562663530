import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, FormControl, Select, MenuItem, Button, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { useEffect, useRef, useState } from "react";
import { convertDateTimeIntoTimezone, getDecodeURI,getEncodedURI, readableBytes } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import { getAllThreatDeviceList, getAllThreatSiteList, getTopThreatTableData } from "../../../actions/Users/authenticateThreatDashboard";
import _ from "lodash";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, {clearCsvDownload} from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import "./../ThreatDashboard.css"
import ThreatPopUpList from "./threatPopList";
import { SET_THREAT_FILTERS } from "../../../actions/types";

const convertToCsv = (data: any[][], userTimezone: string ) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    let LastAttackTime = 'Last Attck Time (' + userTimezone + ')'
    rows.forEach(row => {
        csvRows.push([row[0],row[9],row[5], row[7], row[8], row[4], convertDateTimeIntoTimezone(row[3].toString(), userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)])
    })
    return [['Name','Class','Count', 'Sites', 'Devices', 'Severity', LastAttackTime], ...csvRows]
}

const Top10ThreatTable = (props) => {
    const { timePeriod, handleSiteClick, getTopThreatTableData, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType, setDashboardUptimeSiteStatisticsTableLoading, location, history, authReducer, downloadAsCSV ,top10ThreatTableData, getAllThreatSiteList, getAllThreatDeviceList,threatFilters} = props;
    const _q = getDecodeURI(location?.search);
    const THERAT_NAME = 'threat_name';
    const TOTAL_HITS = 'total_hits';
    const CLASS = 'type'
    const SITES_EFFECTED = 'site_effected';
    const DEVICE_EFFECTED = 'device_effected';
    const SEVRITY_SORT = 'severity'
    const LAST_ATTACK_TIME_SORT="last_attack_time"
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const DEFAULT_PAGE_SIZE = 10;
    const ENTER_KEY_CODE = 'Enter';
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 0);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : "priority,total_hits");
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const [vessels, setVessels] = useState<any[]>([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "SITE LIST",
        subTitle: "SITE LIST",
        onConfirm: {},
      });
    const [confirmDialogDevice, setConfirmDialogDevice] = useState({
        isOpen: false,
        title: "DEVICE LIST",
        subTitle: "DEVICE LIST",
        onConfirm: {},
      });


    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if(vessels?.length > 0) {
            const window_value = (timePeriod?.minutes / 5);
            getTopThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, 0, limit, sort, sortOrder, searchValue, window_value, threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters?.action);
            setPage(0);
        } else {
            setTableData([]);
            setCount(0);
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType,threatFilters])

    useEffect(() => {
        if(!_.isEmpty(top10ThreatTableData)) {
            const data = top10ThreatTableData?.hasOwnProperty('data') ? top10ThreatTableData?.data : {};
            const dataArr:any = [];
            setCount(data?.rows?.[0]?.[5]);
            data?.rows && data?.rows?.forEach((item, i) => {
                if (i != 0) {
                    const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                        acc[columItem] = item[i];
                        return acc;
                    }, {})
                    dataArr.push(itemArr)
                }
            })
            setTableData(dataArr);
        }
    }, [top10ThreatTableData])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'Top-Threats') {
                const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows], authReducer.userTimezone)
                    , {
                        formatters: {
                            6: 'ESC-COMMA'
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 0;
        setPage(0);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getTopThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, limit, field, order, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters?.action)
    }

    const setSorting = (event: any, field: any, order: any) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };
    
    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value-1);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getTopThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, value-1, limit, sort, sortOrder, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters?.action);
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 0;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(0);
        setLimit(Limit);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getTopThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, Limit, sort, sortOrder, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters?.action);
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 0;
        setPage(0);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getTopThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, limit, sort, sortOrder, value, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters?.action);
    };

    const handleChangeTableLimit = (e) => {
        const value = e.target.value;
        setLimit(value);
        const Page = 0;
        setPage(0);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.limit : params.limit = value;
        delete params.page;
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getTopThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, value, sort, sortOrder, searchValue, window_value,threatFilters?.threatName,threatFilters?.deviceName,threatFilters?.ip,threatFilters?.threatClass, threatFilters?.action);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };

    const handleOnSearchClick = () => {
        doSearch(searchValue);
      }
    
      const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
      }
    
      const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
          searchTimeoutRef.current = undefined;
          doSearch(e.target.value);
        }, 1000)
      }

    const handleDownloadReport = () => {
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id : "Top-Threats",
            queryName: 'SNORT_DASHBOARD_TOP_THREAT_TABLE',
            payload: {
                siteid: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                start_time: startDate,
                end_time: endDate,
                "name":  threatFilters?.threatName ? `and msg='${threatFilters?.threatName}'` : "",
                "ip": threatFilters?.ip ? `and ip_address='${threatFilters?.ip}'` : "",
                "class": threatFilters?.threatClass ? `and class='${threatFilters?.threatClass}'` : "",
                "devicename": threatFilters?.deviceName ? `and Device_Name='${threatFilters?.deviceName}'` : "",
                "pageOffset": page ? page : 0,
                "pageSize": count,
                "sort_column":sort ? sort : "priority,total_hits",
                "sort_order":sortOrder ? sortOrder : "desc",
                "action": threatFilters?.action && threatFilters?.action !== "" ? `and action='${threatFilters?.action}'`:"",
                severity: newSummarySeverityType.value && newSummarySeverityType.value !== "all" ? `and severity='${newSummarySeverityType.value.toUpperCase()}'` : "",
                search: searchValue,
            }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleSeverityTypeClick = (severity) => {
        let params: any = getDecodeURI(location?.search);
        params.severityType =severity;
        doNavigate(params);
    }

    const handleSitesEffectedClick = (item:any) => {
        setConfirmDialog({...confirmDialog,isOpen: true})
        getAllThreatSiteList(vessels, newSummaryStartDate, newSummaryEndDate, item?.severity, "" , item?.threatName, '' , '' , item?.type, threatFilters?.action)
    }

    const handleDeviceEffectedClick = (item:any) => {
        setConfirmDialogDevice({...confirmDialogDevice, isOpen: true})
        getAllThreatDeviceList(vessels, newSummaryStartDate, newSummaryEndDate, item?.severity, "" , item?.threatName,'' , '' , item?.type, threatFilters?.action)
    }

    const handleClosePopUpLst = () => {
        setConfirmDialog({...confirmDialog,isOpen: false})
        setConfirmDialogDevice({...confirmDialogDevice, isOpen: false})
    }

    const handleThreatNameClick = (threatName) => {
        let params: any = getDecodeURI(location?.search);
        params.threatName = threatName;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, threatName: threatName } });
    }

    const handleThreatClassNameClick = (threatClassName) => {
        let params: any = getDecodeURI(location?.search);
        params.threatClass = threatClassName;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, threatClass: threatClassName } });
    }


    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">{`Top Threats`}</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div topbarWidth">
                        <Grid item xs={4} sm={4} md={4} lg={4} className="searchbarMaxWidth">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                <ThreatPopUpList confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} handleClosePopUp={handleClosePopUpLst}/>
                <ThreatPopUpList confirmDialog={confirmDialogDevice} setConfirmDialog={setConfirmDialogDevice} handleClosePopUp={handleClosePopUpLst}/>
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page + 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    {/* <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>SITE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell> */}
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, THERAT_NAME)}>NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === THERAT_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, THERAT_NAME, ASC_ORDER)} />
                                                <img src={sort === THERAT_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, THERAT_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CLASS)}>CLASS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === CLASS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CLASS, ASC_ORDER)} />
                                                <img src={sort === CLASS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CLASS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left widthReduce">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOTAL_HITS)}>COUNT</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TOTAL_HITS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTAL_HITS, ASC_ORDER)} />
                                                <img src={sort === TOTAL_HITS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, TOTAL_HITS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left widthReduce">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITES_EFFECTED)}>Sites</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITES_EFFECTED && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITES_EFFECTED, ASC_ORDER)} />
                                                <img src={sort === SITES_EFFECTED && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITES_EFFECTED, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left widthReduce">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DEVICE_EFFECTED)}>Devices</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === DEVICE_EFFECTED && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_EFFECTED, ASC_ORDER)} />
                                                <img src={sort === DEVICE_EFFECTED && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DEVICE_EFFECTED, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SEVRITY_SORT)}>SEVERITY</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SEVRITY_SORT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SEVRITY_SORT, ASC_ORDER)} />
                                                <img src={sort === SEVRITY_SORT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SEVRITY_SORT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LAST_ATTACK_TIME_SORT)}>LAST ATTACK TIME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === LAST_ATTACK_TIME_SORT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LAST_ATTACK_TIME_SORT, ASC_ORDER)} />
                                            <img src={sort === LAST_ATTACK_TIME_SORT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LAST_ATTACK_TIME_SORT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData.length && !setDashboardUptimeSiteStatisticsTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">

                                    {
                                        tableData && tableData.map((item, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                {/* <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity" onClick={() => handleSiteClick({k4Id: item?.k4Id, x: item?.name})}>{item?.name}</TableCell> */}
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.threat_name ? item?.threat_name : 'no data'}</span>}>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleThreatNameClick(item?.threat_name)}>{item?.threat_name ? item?.threat_name : '-'}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.type ? item?.type : 'no data'}</span>}>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleThreatClassNameClick(item?.type)}>{item?.type ? item?.type : '-'}</TableCell>
                                                </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.total_hits ? item?.total_hits : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick"  onClick={() => handleSitesEffectedClick(item)}>{item?.site_effected ? item?.site_effected : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleDeviceEffectedClick(item)}>{item?.device_effected ? item?.device_effected : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick" onClick={() => handleSeverityTypeClick(item?.severity)}>{item?.severity ? item?.severity : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{convertDateTimeIntoTimezone(item.last_attack_time, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    setDashboardUptimeSiteStatisticsTableLoading: state?.authReducer?.setDashboardUptimeSiteStatisticsTableLoading,
    top10ThreatTableData: state?.authReducer?.top10ThreatTableData,
    newSummarySeverityType: state?.authReducer?.newSummarySeverityType,
    allThreatSiteList: state?.authReducer?.allThreatSiteList,
    allThreatSiteListLoading: state?.authReducer?.allThreatSiteListLoading,
    allThreatDeviceList: state?.authReducer?.allThreatDeviceList,
    allTheratDeviceListLoading: state?.authReducer?.allTheratDeviceListLoading,
    threatFilters: state?.authReducer?.threatFilters
  });
  
  export default withRouter(
    connect(mapStateToProps, { getTopThreatTableData, downloadAsCSV, getAllThreatSiteList, getAllThreatDeviceList })(Top10ThreatTable)
  )